import React, { useEffect, useState } from "react";

import { Button, Form } from "react-bootstrap";

import { useSelector } from "react-redux";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import axios from "axios";
import ImageUploader from "../../components/ImageUploader";

const fetchMarketplaceData = async (id, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`/api/marketplace/${id}`, config);
  return response.data;
};

const MarketPlacesEdit = ({ match }) => {
  const marketID = match.params.id;

  const [jsonData, setJsonData] = useState(""); // Store the entire JSON data as a string
  const [apiError, setApiError] = useState(null);
  const [updateMessage, setUpdateMessage] = useState(null);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, loading, error } = userLogin;

  useEffect(() => {
    if (userInfo) {
      const fetchData = async () => {
        try {
          const data = await fetchMarketplaceData(marketID, userInfo.token);
          setJsonData(JSON.stringify(data, null, 2)); // Set entire JSON data as string
        } catch (err) {
          setApiError(err.message);
          console.log(err);
        }
      };
      fetchData();
    }
  }, [marketID, userInfo]);

  const submitHandler = async (event) => {
    event.preventDefault();
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      // Parse the JSON data from the textarea input
      const updatedData = JSON.parse(jsonData);

      const response = await axios.patch(
        `/api/marketplace/${marketID}`,
        updatedData,
        config
      );
      setUpdateMessage("Data Updated Successfully");
      console.log("successfully data sent", response);
    } catch (err) {
      setApiError(err.message);
      console.log(err);
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="warning">{error}</Message>
      ) : apiError ? (
        <Message variant="danger">{apiError}</Message>
      ) : (
        <>
          {updateMessage && (
            <Message variant="success">{updateMessage}</Message>
          )}{" "}
          {/* Display the update message if set */}{" "}
          <ImageUploader marketID={marketID} userInfo={userInfo} />
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="jsonData">
              <label className="mt-2">Marketplace JSON Data</label>
              {/* Render the JSON data textarea */}
              <Form.Control
                as="textarea"
                rows={20}
                value={jsonData}
                onChange={(e) => setJsonData(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group className="d-grid gap-2 mt-2 mb-5">
              <Button variant="secondary" size="lg" type="submit">
                Update
              </Button>
            </Form.Group>
          </Form>
        </>
      )}
    </>
  );
};

export default MarketPlacesEdit;
