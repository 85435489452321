import {
	MARKET_PLACE_CONFIG_REQUEST,
	MARKET_PLACE_CONFIG_SUCCESS,
	MARKET_PLACE_CONFIG_FAIL,
	MARKET_PLACE_UPDATE_REQUEST,
	MARKET_PLACE_UPDATE_SUCCESS,
	MARKET_PLACE_UPDATE_FAIL,
	MARKET_PLACE_UPDATE_RESET,
} from "../constants/marketplaceConstants";


export const marketPlaceReducer = (state = {}, action) => {
	switch (action.type) {
		case MARKET_PLACE_CONFIG_REQUEST:
			return {
				loading: true,
			}
		case MARKET_PLACE_CONFIG_SUCCESS:
			return {
				loading: false,
				marketplaces: action.payload.marketplaces,
				pages: action.payload.pages,
				page: action.payload.page,
			}
		case MARKET_PLACE_CONFIG_FAIL:
			return {
				loading: false,
				error: action.payload,
			}
		default:
			return state
	}
}

export const productUpdateReducer = (state = { marketplaces: {} }, action) => {
	switch (action.type) {
		case MARKET_PLACE_UPDATE_REQUEST:
			return { loading: true }
		case MARKET_PLACE_UPDATE_SUCCESS:
			return { loading: false, success: true, marketplaces: action.payload }
		case MARKET_PLACE_UPDATE_FAIL:
			return { loading: false, error: action.payload }
		case MARKET_PLACE_UPDATE_RESET:
			return { marketplaces: {} }
		default:
			return state
	}
}