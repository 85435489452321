import axios from "axios";
import React from "react";
import { useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import Bundle from "../components/Bundle";
import env from "dotenv";

env.config();

const BundleScreen = ({ history }) => {
  const [bundles, setBundles] = useState();

  const storeConfig = useSelector((state) => state.storeConfig);

  const { store } = storeConfig;

  useEffect(() => {
    const getBundles = async () => {
      const { data } = await axios.get(`/api/bundle/marketplace/${store._id}`);
      setBundles(data);
    };
    getBundles();
  }, [store._id]);

  return (
    <div>
      {bundles && (
        <Row>
          {bundles.map((bundle, index) => (
            <Col key={index} xs={12} sm={12} md={12} lg={12} xl={12}>
              <Bundle history={history} bundle={bundle} />
            </Col>
          ))}
        </Row>
      )}
    </div>
  );
};

export default BundleScreen;
