import React, { useEffect, useState } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Button, Row, Col, Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import PaginateOrders from "../components/PaginateOrders";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { listOrders, cancelledOrder } from "../actions/orderActions";
import { payOrder, deliverOrder } from "../actions/orderActions";
import { BsTelephoneOutbound } from "react-icons/bs";
import axios from "axios";

const OrderListScreen = ({ history, match }) => {
  const pageNumber = match.params.pageNumber || 1;
  const orderStatus = match.params.orderStatus || "new";
  const tabHistory = history;
  const dispatch = useDispatch();
  const [key, setKey] = useState(orderStatus);
  const orderList = useSelector((state) => state.orderList);
  const { loading, error, orders, pages, page } = orderList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userInfo.token}`,
    },
  };

  useEffect(() => {
    if (
      userInfo &&
      (userInfo.role.includes("admin") || userInfo.role.includes("Admin"))
    ) {
      switch (key) {
        case "new":
          dispatch(listOrders(pageNumber, false, false, "online"));
          break;
        case "paid":
          dispatch(listOrders(pageNumber, true, false, "online"));
          break;
        case "delivered":
          dispatch(listOrders(pageNumber, true, true, "online"));
          break;
        case "POS":
          dispatch(listOrders(pageNumber, false, false, "pos"));
          break;
        case "invoice":
          dispatch(listOrders(pageNumber, false, false, "invoice"));
          break;
        case "po":
          dispatch(listOrders(pageNumber, false, false, "po"));
          break;
        case "cancelled":
          dispatch(listOrders(pageNumber, false, false, "online", true));
          break;
        case "all":
          dispatch(listOrders(pageNumber));
          break;
        default:
          dispatch(listOrders(false, false, pageNumber));
          break;
      }
    } else {
      history.push("/login");
    }
  }, [dispatch, history, userInfo, key, pageNumber]);

  const handleTabSelect = (k) => {
    setKey(k);
    tabHistory.push(`/admin/orderlist/${k}/1`); // Reset page to 1
  };

  /*  const deleteHandler = (id) => {
    if (window.confirm("Are You Sure")) {
      dispatch(deleteOrder(id));
    }
  }; */
  const cancelledHandler = (id) => {
    if (window.confirm("Are You Sure")) {
      dispatch(cancelledOrder(id));
    }
  };

  const paidHandler = (order) => {
    dispatch(payOrder(order));
  };

  const deliverHandler = (order) => {
    dispatch(deliverOrder(order));
  };

  const callingToCustomer = async (oderId) => {
    try {
      const result = await axios.post(`/api/orders/${oderId}/call`, {}, config)
      return result
    } catch (error) {
      console.error("Error occurred while calling the customer:", error);
    }
  }
  const time = (utc) => {
    let time = new Date(utc).toLocaleString("en-IN", {
      timeZone: "Asia/Kolkata",
    });

    return (
      "Date : " +
      time.split(",")[0].substring(0, 10) +
      " Time : " +
      time.split(",")[1].slice(0, 5) +
      " " +
      time.split(",")[1].slice(9, 12)
    );
  };

  return (
    <>
      <h1>Order Details </h1>
      <Row
        style={{
          textAlign: "center",
          verticalAlign: " middle",
          lineHeight: "20px",
        }}
      >
        <Col lg={12} md={12} xs={12}>
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={handleTabSelect}
          >
            <Tab eventKey="new" title="New"></Tab>
            <Tab eventKey="paid" title="Paid"></Tab>
            <Tab eventKey="delivered" title="Delivered"></Tab>
            <Tab eventKey="POS" title="POS"></Tab>
            <Tab eventKey="cancelled" title="Cancelled"></Tab>
            <Tab eventKey="invoice" title="Invoices"></Tab>
            <Tab eventKey="po" title="Purchase orders"></Tab>
            <Tab eventKey="all" title="All"></Tab>
          </Tabs>
        </Col>
      </Row>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="warning">{error}</Message>
      ) : (
        <>
          <>
            <div className="mobileHiddenList">
              <Row
                xs={12}
                sm={12}
                className="justify-content-center mb-3 mt-3 tableHead"
              >
                <Col>Phone</Col>
                <Col>Photo</Col>
                <Col>Time</Col>
                <Col>Total</Col>
                <Col>Store</Col>
                <Col>Action</Col>
                {userInfo &&
                  userInfo.role.includes("superAdmin") &&
                  key !== "cancelled" &&
                  key !== "delivered" &&
                  key !== "all" ? (
                  <Col md={1} lg={1}>
                    Cancel
                  </Col>
                ) : null}
              </Row>
              <div>
                {orders?.map((order, i) => (
                  <Row key={Math.random()} className="justify-content-center tableData">
                    <Col>
                      {key === "POS" ? (
                        <span style={{ marginLeft: "0.7rem" }}>
                          <a style={{ color: "#268bd2" }} href={`tel:${order.user?.phone || "number"}`}>
                            {order.user?.phone || "number"}
                          </a>
                        </span>
                      ) : (
                        <button onClick={() => callingToCustomer(order._id)} className="phone-button" aria-label="Call Customer">
                          <BsTelephoneOutbound size={19} />
                        </button>
                      )}
                    </Col>

                    <Col>
                      <img
                        src={order.orderItems[0].image}
                        alt={order.orderItems[0].name}
                        width="100"
                      />
                    </Col>
                    <Col>{time(order.createdAt)} </Col>
                    <Col>₹ {order.totalPrice} </Col>
                    <Col>{order.store.name}</Col>
                    <Col>
                      <LinkContainer to={`/order/${order._id}`}>
                        <Button variant="primary">Details</Button>
                      </LinkContainer>
                      {userInfo && userInfo.role.includes("deliveryAdmin") ? (
                        <>
                          {!order.isPaid && (
                            <Button
                              variant="secondary"
                              style={{ color: "#fff" }}
                              className="btn-md "
                              onClick={() => paidHandler(order)}
                            >
                              Paid
                            </Button>
                          )}
                          {!order.isDelivered && order.isPaid && (
                            <Button
                              variant="secondary"
                              style={{ color: "#fff" }}
                              className="btn-md  "
                              onClick={() => deliverHandler(order)}
                            >
                              Deliver
                            </Button>
                          )}
                        </>
                      ) : null}
                    </Col>
                    {userInfo &&
                      userInfo.role.includes("superAdmin") &&
                      order.status !== "cancelled" &&
                      order.isDelivered === false &&
                      key !== "all" ? (
                      <Col className="tableData" md={1}>
                        <Button
                          variant="light"
                          style={{ color: "red" }}
                          className="btn-sm"
                          onClick={() => cancelledHandler(order._id)}
                        >
                          <span style={{ color: "red" }}>cancel</span>
                        </Button>
                      </Col>
                    ) : null}
                  </Row>
                ))}
              </div>
            </div>

            <div className="mobileHidden">
              {orders?.map((order) => (
                <div className="border-bottom">
                  <Row key={Math.random()}>
                    <Col xs={3} sm={3} style={{ display: "grid" }}>
                      <img
                        src={
                          order.orderItems[0].image
                            ? order.orderItems[0].image
                            : "./img/sample.jpg"
                        }
                        style={{ padding: "0.5rem" }}
                        className="img-fluid round-start fitImageCart"
                        alt={order.name}
                      />
                    </Col>

                    <Col>
                      {userInfo.role === "admin" ||
                        userInfo.role === "superAdmin" || userInfo.role === "deliveryAdmin" ? (
                        <div style={{ display: 'inline' }}>
                          phone :{" "}
                          {key === "POS" ? (
                            <span style={{ marginLeft: "0.7rem" }}>
                              <a style={{ color: "#268bd2" }} href={`tel:${order.user?.phone || "number"}`}>
                                {order.user?.phone || "number"}
                              </a>
                            </span>
                          ) : (
                            <button onClick={() => callingToCustomer(order._id)} className="phone-button" aria-label="Call Customer">
                              <BsTelephoneOutbound size={19} />
                            </button>
                          )}
                          {/*  <span style={{ marginLeft: "0.7rem" }}>
                            {" "}
                            <a style={{ color: "#268bd2 " }} href="tel:number">
                              {order.user ? order.user.phone : "number"}
                            </a>
                          </span> */}
                        </div>
                      ) : (
                        <div>
                          phone : {order.user ? order.user.phone : "number"}
                        </div>
                      )}
                      <div>
                        Total Price :{" "}
                        <span style={{ color: "black" }}>
                          ₹ {order.totalPrice}
                        </span>
                      </div>

                      {userInfo.role === "superAdmin" ||
                        userInfo.role === "deliveryAdmin" ? (
                        <div>
                          Dilivery Time :{" "}
                          <span style={{ color: "black" }}>
                            {order.estimatedDeliveryTime}
                          </span>
                        </div>
                      ) : null}
                      <div>
                        Store Name :
                        <span style={{ marginLeft: "0.5rem" }}>
                          {userInfo && userInfo.role.includes("superAdmin")
                            ? order.store.name
                            : null}
                        </span>
                      </div>
                      <div>{time(order.createdAt)}</div>
                    </Col>
                    <Col xs={2} sm={2} className="text-center">
                      {userInfo &&
                        userInfo.role.includes("superAdmin") &&
                        order.status !== "cancelled" &&
                        order.isDelivered === false &&
                        key !== "all" ? (
                        <Button
                          variant="light"
                          style={{ color: "red" }}
                          className="btn-sm mb-3 float-end"
                          onClick={() => cancelledHandler(order._id)}
                        >
                          cancel
                        </Button>
                      ) : null}
                    </Col>
                  </Row>
                  <Row>
                    <LinkContainer to={`/order/${order._id}`}>
                      <Button
                        className="btn-sm mb-3  float-end"
                        size="sm"
                        variant="secondary"
                      >
                        Details
                      </Button>
                    </LinkContainer>
                    {userInfo && userInfo.role.includes("deliveryAdmin") ? (
                      <>
                        {!order.isPaid && (
                          <Button
                            variant="light"
                            style={{ color: "green" }}
                            className="btn-sm mb-3 float-end"
                            onClick={() => paidHandler(order)}
                          >
                            Paid
                          </Button>
                        )}
                        {!order.isDelivered && order.isPaid && (
                          <Button
                            variant="light"
                            style={{ color: "green" }}
                            className="btn-sm mb-3 float-end"
                            onClick={() => deliverHandler(order)}
                          >
                            Deliver
                          </Button>
                        )}
                      </>
                    ) : null}
                  </Row>
                </div>
              ))}
            </div>
          </>
          <PaginateOrders pages={pages} orderStatus={key} page={page} />
        </>
      )}
    </>
  );
};

export default OrderListScreen;
