import { useState, React } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Card, Toast } from "react-bootstrap";
import { BsPlusLg, BsDashLg } from "react-icons/bs";
import { addToCart } from "../actions/cartActions";

const Product = ({ product }) => {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState(null);
  const [variant, setVariant] = useState(false);
  const [num, setNum] = useState(1);
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const storeConfig = useSelector((state) => state.storeConfig);
  const { store } = storeConfig;

  const dispatch = useDispatch();

  let incNum = (e) => {
    e.preventDefault();

    setNum(Number(num) + 1);
    dispatch(addToCart(product._id, num + 1));
  };

  const decNum = (e) => {
    e.preventDefault();
    if (num > 1) {
      setNum(num - 1);
      dispatch(addToCart(product._id, num - 1));
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    setNum(e.target.value);
  };

  const AddToCart = (e) => {
    const cartPrice = cartItems.reduce(
      (acc, item) => acc + item.price * item.qty,
      0
    );
    setVariant(true);

    store.discountedProducts
      .filter((item) => product._id === item.id)
      .map((item) => {
        if (cartPrice < item.threshold) {
          setMessage(item.msg);
          setShow(true);
          setVariant(false);
        } else if (product.countInStock === 0) {
          setMessage("out of stock");
          setShow(true);
          setVariant(false);
        } else {
          setVariant(false);
        }

        return true;
      });

    dispatch(addToCart(product._id, num));

    e.preventDefault();
  };

  return (
    <Card className="my-1 p-1 rounded zoom card-size">
      <Link to={`/product/${product._id}`}>
        <Card.Img
          className="card-img rounded mx-auto d-block"
          src={
            product.images[0]
              ? product.images[0]
              : "https://mufeed-images.s3.ap-south-1.amazonaws.com/image-coming-soon.png"
          }
          alt={
            window.location.origin +
            "https://mufeed-images.s3.ap-south-1.amazonaws.com/image-coming-soon.png"
          }
          variant="top"
        />
      </Link>
      {/* <span className='bottomLine'></span> */}
      <Card.Body className="card-size-body">
        <Link to={`/product/${product._id}`}>
          <Card.Title>
            <h2 style={{ margin: "0" }} className="card-name">
              {" "}
              {product.name}
            </h2>
            <div
              style={{ margin: "0", height: "1.5rem" }}
              className="card-name-size"
            >
              {product.size}
            </div>
          </Card.Title>
        </Link>

        <div className="card-text-inline">
          <Card.Text style={{ marginTop: "-1rem" }}>
            <span style={{ marginLeft: "0.2rem", display: "inline" }}>
              {" "}
              ₹{product.price}
            </span>
            {product.mrp > product.price && (
              <span style={{ display: "inline" }} className="card-text-mrp">
                {" "}
                ₹{product.mrp}
              </span>
            )}

            {parseInt(
              (((product.mrp - product.price) / product.mrp) * 100).toFixed(0)
            ) === 0 ? (
              " "
            ) : (
              <strong className="card-text-off">
                -{" "}
                {(((product.mrp - product.price) / product.mrp) * 100).toFixed(
                  0
                )}
                %
              </strong>
            )}
          </Card.Text>
        </div>
        {product.countInStock >= 1 ? (
          <Card.Text className=" gap-2  mx-auto" style={{ margin: "0" }}>
            <span className="d-grid gap-2  mx-auto">
              <Button
                className="countButton "
                style={{
                  display: variant ? "none" : "block",
                  textAlign: "center",
                }}
                variant="outline-success"
                onClick={AddToCart}
              >
                ADD TO CART
              </Button>
            </span>

            <span
              className="card-text-inline mx-auto"
              style={{ display: variant ? "block" : "none" }}
            >
              <span style={{ justifyContent: "space-around", display: "flex" }}>
                <Button
                  style={{ fontWeight: "900" }}
                  className="btn-sm"
                  variant="outline-success"
                  onClick={(e) => decNum(e)}
                >
                  <BsDashLg />
                </Button>

                <input
                  style={{ fontWeight: "600" }}
                  className="countInput "
                  type="text"
                  value={num}
                  onChange={(e) => handleChange(e)}
                  disabled
                />

                <Button
                  style={{ fontWeight: "900" }}
                  className="btn-sm"
                  variant="outline-success"
                  onClick={(e) => incNum(e)}
                >
                  <BsPlusLg />
                </Button>
              </span>
            </span>
          </Card.Text>
        ) : (
          <Card.Text className=" gap-2  mx-auto" style={{ margin: "0" }}>
            Out of stock
          </Card.Text>
        )}
        <Card.Text>
          <Toast
            className="toaster-start"
            onClose={() => setShow(false)}
            show={show}
            delay={2000}
            autohide
          >
            <Toast.Header closeButton={false} className="toaster-head">
              <span className="toaster-text">{message}</span>
            </Toast.Header>
          </Toast>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default Product;
