import env from "dotenv";
import axios from "axios";

env.config();
axios.defaults.baseURL = process.env.REACT_APP_API_SERVER;

let marketplaceId;

export const getMarketPlaceId = async (pinCode) => {
  try {
    const res = await axios.get(`/api/marketplace/places/${pinCode}`);
    marketplaceId = res.data._id;
  } catch (error) {
    console.log(error);
    marketplaceId = "undefined";
  }
  console.log("marketplaceId hai ye: " + marketplaceId);
  return marketplaceId;
};

export const MARKETPLACE_ID = marketplaceId;

/* export const MARKETPLACE_ID = id
export const MARKETPLACE_ID = process.env.REACT_APP_MARKETPLACE_ID; */
