import React, { useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { topSelling } from "../actions/orderActions";
import Message from "../components/Message";
import Loader from "../components/Loader";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export function TopSellsBar({ history }) {
  const dispatch = useDispatch();
  const topSells = useSelector((state) => state.topSellingProducts);
  const { topSellingProducts, loading, error } = topSells;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (
      userInfo &&
      (userInfo.role.includes("admin") ||
        userInfo.role.includes("superAdmin") ||
        userInfo.role.includes("deliveryAdmin"))
    ) {
      dispatch(topSelling());
    } else {
      history.push("/login");
    }
  }, [dispatch, history, userInfo]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="warning">{error}</Message>
      ) : (
        <>
          <p className="DashboardHeading">Top Selling Products</p>
          <Bar
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: "top",
                },
              },
            }}
            data={{
              labels: topSellingProducts.map((product) => product._id),
              datasets: [
                {
                  label: "products",
                  data: topSellingProducts.map((product) => product.qty),
                  backgroundColor: "rgba(53, 162, 235, 0.5)",
                },
              ],
            }}
          />
        </>
      )}
    </>
  );
}
