import { React, useState } from "react";
import { Pagination } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const Paginate = ({
  pages,
  page,
  isAdmin = false,
  category = "",
  subCategory = "",
  keyword = "",
  brand = "",
  storeId = "",
  productList = false,
  userList = false,
}) => {
  const [x, setX] = useState(page);

  const handlePageChange = (pageNumber) => {
    if (pageNumber <= pages && pageNumber > 0) {
      setX(pageNumber);
    }
  };

  function pageLink(x) {
    if (!isAdmin) {
      if (keyword) {
        return `/search/${keyword}/page/${x}`;
      } else {
        if (category) {
          return `/category/${category}/page/${x}`;
        } else {
          if (subCategory) {
            return `/subCategory/${subCategory}/page/${x}`;
          } else {
            if (storeId) {
              return `/store/${storeId}/page/${x}`;
            } else {
              if (brand) {
                return `/brand/${brand}/page/${x}`;
              } else {
                return `/page/${x}`;
              }
            }
          }
        }
      }
    } else {
      if (productList) {
        return `/admin/productlist/${x}`;
      } else if (userList) {
        return `/admin/userlist/${x}`;
      }
    }
  }

  let start = pages - page > 8 ? page - 1 : page - 4;
  let end = pages - page > 8 ? page + 3 : page;

  return (
    pages > 1 && (
      <Pagination size="md" className={"mt-3"}>
        {/* Direct first number in  pagination */}
        <LinkContainer to={pageLink(1)}>
          <Pagination.First onClick={() => handlePageChange(1)}>
            {" "}
            First
          </Pagination.First>
        </LinkContainer>

        {/* previous number in  pagination */}
        <LinkContainer to={pageLink(x - 1 >= 1 ? x - 1 : page)}>
          <Pagination.Prev onClick={() => handlePageChange(x - 1)} />
        </LinkContainer>
        {/* showing few numbers in pagination */}
        {[...Array(pages).keys()].slice(start, end).map((x) => (
          <LinkContainer key={x + 1} to={pageLink(x + 1)}>
            <Pagination.Item active={x + 1 === page}>{x + 1}</Pagination.Item>
          </LinkContainer>
        ))}
        {/* next number in  pagination */}
        <LinkContainer to={pageLink(x + 1 < pages ? x + 1 : pages)}>
          <Pagination.Next onClick={() => handlePageChange(x + 1)} />
        </LinkContainer>
        {/* Direct Last number in  pagination */}
        <LinkContainer to={pageLink(pages)}>
          <Pagination.Last onClick={() => handlePageChange(pages)}>
            Last
          </Pagination.Last>
        </LinkContainer>
      </Pagination>
    )
  );
};

export default Paginate;
