import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store.js";
import "./bootstrap.min.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
/* import Emergency from './screens/Emergency.js' */

navigator.serviceWorker.register("./serviceworker.js");

ReactDOM.render(
  <Provider store={store}>
    {/* <Emergency /> */}
    <App />
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
