import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import Card from "react-bootstrap/Card";
import { useSelector } from "react-redux";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { rupeeFormat } from "../components/utils/CommonFunctions.js";

import axios from "axios";

ChartJS.register(ArcElement, Tooltip, Legend);

export function MonthlySummary({ orderType }) {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [ordersSummary, setOrdersSummary] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const groupBy = "day";
    // Date time 12 AM IST current date
    let today = new Date();

    // Get the year and month of today's date
    let year = today.getFullYear();
    let month = today.getMonth() + 1; // add 1 because getMonth() returns 0-11

    // Format as ISO date
    let from = `${year}-${month.toString().padStart(2, "0")}-01`;
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    if (orderType === "pos") {
      axios
        .get(`/api/orders/report/pos?groupBy=${groupBy}&from=${from}`, config)
        .then((res) => {
          console.log(res.data);
          setOrdersSummary(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setError(err);
        });
    } else {
      axios
        .get(`/api/orders/report?groupBy=${groupBy}&from=${from}`, config)
        .then((res) => {
          console.log(res.data);
          setOrdersSummary(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setError(err);
        });
    }
  }, [userInfo.token, orderType]);

  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant="warning">{error}</Message>
  ) : (
    <>
      <Card
        style={{
          background:
            "linear-gradient(to top right, #2aa198 -16%, #ffffff 31%)",
        }}
      >
        <Card.Body>
          <Card.Title>
            <h4 style={{ textAlign: "center", color: "#2aa198" }}>
              Monthly Sales
            </h4>
          </Card.Title>
          <Card.Text>
            <h5>
              Amount : ₹
              {rupeeFormat(
                ordersSummary.reduce(
                  (total, summary) => summary.totalPrice + total,
                  0
                )
              )}
            </h5>
          </Card.Text>
          {userInfo.role === "superAdmin" ||
          userInfo.role === "deliveryAdmin" ? (
            <Card.Text>
              <h5>
                Revenue: ₹
                {rupeeFormat(
                  ordersSummary.reduce(
                    (total, summary) =>
                      summary.totalProfit + summary.totalShipping + total,
                    0
                  )
                )}
              </h5>
            </Card.Text>
          ) : null}

          {(userInfo.role === "superAdmin" ||
            userInfo.role === "deliveryAdmin") &&
          orderType !== "pos" ? (
            <Card.Text>
              <h5>
                Delivery Charges: ₹
                {rupeeFormat(
                  ordersSummary.reduce(
                    (total, summary) => summary.orders * 10 + total,
                    0
                  )
                )}
              </h5>
            </Card.Text>
          ) : null}
          {(userInfo.role === "superAdmin" ||
            userInfo.role === "deliveryAdmin") &&
          orderType !== "pos" ? (
            <Card.Text>
              <h5>
                Profit: ₹
                {rupeeFormat(
                  ordersSummary.reduce(
                    (total, summary) =>
                      summary.totalProfit + summary.totalShipping + total,
                    0
                  ) -
                    ordersSummary.reduce(
                      (total, summary) => summary.orders * 10 + total,
                      0
                    )
                )}
              </h5>
            </Card.Text>
          ) : null}
          <Card.Text>
            <h6>
              Monthly Orders :{" "}
              {rupeeFormat(
                ordersSummary.reduce(
                  (total, summary) => summary.orders + total,
                  0
                )
              )}
            </h6>
          </Card.Text>
          <Card.Text>
            <h6>
              Monthly Items :{" "}
              {rupeeFormat(
                ordersSummary.reduce(
                  (total, summary) => summary.items + total,
                  0
                )
              )}
            </h6>
          </Card.Text>
          <Card.Text>
            <h6>
              Monthly Quantity :{" "}
              {rupeeFormat(
                ordersSummary.reduce(
                  (total, summary) => summary.quantity + total,
                  0
                )
              )}
            </h6>
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
}
