import React, { useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { listMyOrders } from "../actions/orderActions";

const MyOrderScreen = ({ history }) => {
  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const storeConfig = useSelector((state) => state.storeConfig);
  const { store } = storeConfig;

  const orderListMy = useSelector((state) => state.orderListMy);
  const { loading, error, orders } = orderListMy;

  useEffect(() => {
    if (!userInfo) {
      history.push("/login");
    } else {
      if (!user.name) {
        dispatch(listMyOrders(store._id));
      }
    }
  }, [history, userInfo, dispatch, user, store._id]);

  return (
    <>
      <h1> My Orders </h1>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="warning">{error}</Message>
      ) : (
        <>
          <Row
            style={{
              color: "black",
              fontWeight: "bold",
              textAlign: "center",
              verticalAlign: " middle",
              lineHeight: "30px",
            }}
            className="justify-content-center mb-3"
          >
            <Col lg={2} md={4} xs={4}>
              Photo
            </Col>
            <Col xs={4}>Status</Col>
            <Col xs={3}>Action</Col>
          </Row>
          {orders?.orders?.map((order) => (
            <Row
              style={{ textAlign: "center", verticalAlign: " middle" }}
              className="justify-content-center mb-2"
            >
              <Col lg={2} md={4} xs={4}>
                <LinkContainer to={`/order/${order._id}`}>
                  <img
                    src={order.orderItems[0].image}
                    alt={order.orderItems[0].name}
                    width="50%"
                  />
                </LinkContainer>
              </Col>
              <Col xs={4}>
                {order.isDelivered ? (
                  <>
                    <h5 style={{ color: "green" }}>Delivered</h5>
                  </>
                ) : order.isPaid ? (
                  <>
                    <h5 style={{ color: "green" }}>Paid</h5>
                  </>
                ) : order.status === "cancelled" ? (
                  <>
                    <h5 style={{ color: "#ff7045" }}>Cancelled</h5>
                  </>
                ) : (
                  <>
                    <h5 style={{ color: "green" }}>
                      Arriving in {order.estimatedDeliveryTime}
                    </h5>
                  </>
                )}
              </Col>
              <Col xs={3}>
                <LinkContainer to={`/order/${order._id}`}>
                  <Button variant="primary">Details</Button>
                </LinkContainer>
              </Col>
            </Row>
          ))}
        </>
      )}
    </>
  );
};

export default MyOrderScreen;
