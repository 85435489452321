import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import Card from "react-bootstrap/Card";
import { useSelector } from "react-redux";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { rupeeFormat } from "../components/utils/CommonFunctions.js";

import axios from "axios";

ChartJS.register(ArcElement, Tooltip, Legend);

export function OrdersSummary({ orderType }) {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [ordersSummary, setOrdersSummary] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const groupBy = "day";
    // Date time 12 AM IST current date
    const from = new Date(new Date().setHours(0, 0, 0, 0)).toISOString();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    if (orderType === "pos") {
      axios
        .get(`/api/orders/report/pos?groupBy=${groupBy}&from=${from}`, config)
        .then((res) => {
          console.log(res.data);
          setOrdersSummary(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setError(err);
        });
    } else {
      axios
        .get(`/api/orders/report?groupBy=${groupBy}&from=${from}`, config)
        .then((res) => {
          console.log(res.data);
          setOrdersSummary(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setError(err);
        });
    }
  }, [userInfo.token, orderType]);

  /*  function rupeeFormat(number) {
    return new Intl.NumberFormat("en-IN", {
      maximumSignificantDigits: 3,
    }).format(number);
  } */

  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant="warning">{error}</Message>
  ) : (
    <>
      <Card
        style={{
          height: "100%",
          background:
            "linear-gradient(to top right, #2aa198 -16%, #ffffff 31%)",
        }}
      >
        <Card.Body>
          <Card.Title>
            <h4 style={{ textAlign: "center", color: "#2aa198" }}>
              Todays Sales
            </h4>
          </Card.Title>
          <Card.Text>
            <h5>
              Amount : ₹
              {rupeeFormat(ordersSummary.map((summary) => summary.totalPrice))}
            </h5>
          </Card.Text>
          {userInfo.role === "superAdmin" ||
          userInfo.role === "deliveryAdmin" ? (
            <Card.Text>
              <h5>
                Revenue: ₹
                {rupeeFormat(
                  ordersSummary.map(
                    (summary) => summary.totalProfit + summary.totalShipping
                  )
                )}
              </h5>
            </Card.Text>
          ) : null}

          {(userInfo.role === "superAdmin" ||
            userInfo.role === "deliveryAdmin") &&
          orderType !== "pos" ? (
            <Card.Text>
              <h5>
                Delivery Charges: ₹
                {rupeeFormat(
                  ordersSummary.map((summary) => summary.orders * 10)
                )}
              </h5>
            </Card.Text>
          ) : null}
          {(userInfo.role === "superAdmin" ||
            userInfo.role === "deliveryAdmin") &&
          orderType !== "pos" ? (
            <Card.Text>
              <h5>
                Profit: ₹
                {rupeeFormat(
                  ordersSummary.map(
                    (summary) => summary.totalProfit + summary.totalShipping
                  ) - ordersSummary.map((summary) => summary.orders * 10)
                )}
              </h5>
            </Card.Text>
          ) : null}
          <Card.Text>
            <h6>
              Daily Orders :{" "}
              {rupeeFormat(ordersSummary.map((summary) => summary.orders))}
            </h6>
          </Card.Text>
          <Card.Text>
            <h6>
              Daily Items :{" "}
              {rupeeFormat(ordersSummary.map((summary) => summary.items))}
            </h6>
          </Card.Text>
          <Card.Text>
            <h6>
              Daily Quantity :{" "}
              {rupeeFormat(ordersSummary.map((summary) => summary.quantity))}
            </h6>
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
}
