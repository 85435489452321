import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import Resizer from "react-image-file-resizer";
import axios from "axios";
import Loader from "../components/Loader";
import Message from "../components/Message";

const ImageUploader = ({ marketID, userInfo }) => {
  const [images, setImages] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [apiError, setApiError] = useState(null);

  const uploadFileHandler = async (e) => {
    setUploading(true);
    const formData = new FormData();
    const files = e.target.files;
    if (files) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        Resizer.imageFileResizer(
          file,
          750,
          750,
          "JPEG",
          400,
          0,
          (blob) => {
            formData.append("images", blob, file.name);
            axios
              .post(`/api/upload`, formData, {
                headers: {
                  Authorization: `Bearer ${userInfo.token}`,
                  "Content-Type": "multipart/form-data",
                },
              })
              .then((response) => {
                setUploading(false);
                const updatedImages = response.data.split(",");
                setImages(updatedImages);
              })
              .catch((err) => {
                console.log(err);
                setApiError(err.message);
                setUploading(false);
              });
          },
          "blob"
        );
      }
    }
  };

  return (
    <div>
      {uploading && <Loader />}
      {apiError && <Message variant="danger">{apiError}</Message>}
      <Form.Group controlId="images">
        <label className="mt-2">Upload Images</label>
        <div className="d-flex">
          <label className="btn btn-secondary">
            Choose File
            <Form.File
              id="image-file"
              multiple
              hidden
              custom
              onChange={uploadFileHandler}
            />
          </label>
          <Button variant="primary" onClick={uploadFileHandler}>
            Upload
          </Button>
        </div>
        <div className="mt-2">
          {images.map((image, index) => (
            <div key={index} className="d-flex align-items-center">
              <img
                style={{ height: "40px", width: "40px", margin: "5px" }}
                src={image}
                alt={`img-${index}`}
              />
              <span>{image}</span>
            </div>
          ))}
        </div>
      </Form.Group>
    </div>
  );
};

export default ImageUploader;
