import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  ListGroup,
  Image,
  Form,
  Button,
  Card,
} from "react-bootstrap";
import {
  addToCart,
  addBundleToCart,
  removeFromCart,
} from "../actions/cartActions";

const CartScreen = ({ match, location, history }) => {
  const productId = match.params.id;
  const params = new URLSearchParams(window.location.search);

  const type = params.get("type");

  const qty = location.search ? Number(location.search.split("=")[1]) : 1;

  const storeConfig = useSelector((state) => state.storeConfig);
  const { store } = storeConfig;

  const minimumOrderValue = store.minimumOrderValue || 100;
  const deliveryCharges = store.deliveryCharges || 10;

  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  };

  cart.shippingPrice = addDecimals(
    cart.itemsPrice >= minimumOrderValue ? 0 : deliveryCharges
  );

  const cartTotalValue =
    minimumOrderValue -
    cartItems.reduce((acc, item) => acc + item.qty * item.price, 0).toFixed(0);

  useEffect(() => {
    if (productId && type === "bundle") {
      dispatch(addBundleToCart(productId, qty));
    } else {
      dispatch(addToCart(productId, qty));
    }
  }, [dispatch, productId, qty, type]);

  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id));
  };

  const checkoutHandler = () => {
    // TODO: Checkout
    // if (shippingAddress && paymentMethod && quickCheckout === true) {
    //   history.push('/placeorder')
    //  }
    //  else{
    // history.push('/login?redirect=shipping')
    //  }

    history.push("/login?redirect=shipping");
  };

  return (
    <Row>
      {/* ---------------------- mobileHiddenList  hidden in mobile ------------- */}
      <Col className="mobileHiddenList" xs={12} md={8}>
        <h1>Cart</h1>
        {cartItems?.length === 0 ? (
          <h4>
            Your cart is empty<Link to="/">{' '}Go to homepage</Link>
          </h4>
        ) : (
          <ListGroup variant="flush">
            {cartItems.map((item) => (
              <ListGroup.Item key={item.product}>
                <Row>
                  <Col className="mt-2" xs={2} md={2}>
                    <Image
                      className="fitImage"
                      src={item.image}
                      alt={item.name}
                      fluid
                    ></Image>
                  </Col>
                  <Col xs={3} md={3}>
                    <Link to={`/product/${item.product}`}>{item.name}</Link>
                  </Col>
                  <Col xs={3} md={2}>
                    ₹ {item.price}
                  </Col>
                  <Col xs={2} md={2}>
                    <Form.Control
                      as="select"
                      value={item.qty}
                      onChange={(e) =>
                        dispatch(
                          addToCart(item.product, Number(e.target.value))
                        )
                      }
                    >
                      {[...Array(item.countInStock).keys()]
                        .slice(0, 10)
                        .map((x) => (
                          <option key={x + 1} value={x + 1}>
                            {x + 1}
                          </option>
                        ))}
                    </Form.Control>
                  </Col>
                  <Col xs={2} md={2}>
                    <Button
                      type="button"
                      variant="light"
                      id="delete"
                      onClick={() => removeFromCartHandler(item.product)}
                    >
                      <i className="fas fa-trash"></i>
                    </Button>
                  </Col>
                </Row>
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </Col>
      <Col className="mobileHiddenList" xs={12} md={4}>
        <Card className="mb-2">
          <ListGroup variant="dark">
            <ListGroup.Item>
              TotalItems :
              <span
                style={{
                  color: "black",
                  float: "right",
                  fontSize: "1.3rem",
                  marginRight: "1rem",
                }}
              >
                {" "}
                {cartItems.reduce((acc, item) => acc + item.qty, 0)}
              </span>
            </ListGroup.Item>
            <ListGroup.Item>
              Total price:
              <span
                style={{
                  color: "black",
                  float: "right",
                  fontSize: "1.3rem",
                  marginRight: "1rem",
                }}
              >
                {" "}
                ₹{" "}
                {cartItems
                  .reduce((acc, item) => acc + item.qty * item.price, 0)
                  .toFixed(0)}
              </span>
            </ListGroup.Item>
            {cartTotalValue > 0 && (
              <ListGroup.Item>
                <Row>
                  <Col style={{ color: "green" }}>
                    Add items worth{" "}
                    <span style={{ color: "#000" }}>₹{cartTotalValue}</span> for
                    Free Delievry
                  </Col>
                </Row>
              </ListGroup.Item>
            )}
            <ListGroup.Item>
              Total Savings:
              <span
                style={{
                  color: "green",
                  float: "right",
                  fontSize: "1.3rem",
                  marginRight: "1rem",
                }}
              >
                ₹{" "}
                {cartItems
                  .reduce(
                    (acc, item) => acc + item.qty * (item.mrp - item.price),
                    0
                  )
                  .toFixed(0)}
              </span>
            </ListGroup.Item>

            <ListGroup.Item className="d-grid gap-2">
              <Button
                size="lg"
                type="button"
                variant="secondary"
                id="proceed"
                style={{ display: cartItems?.length === 0 ? "none" : "block" }}
                onClick={checkoutHandler}
              >
                Proceed
              </Button>
            </ListGroup.Item>
          </ListGroup>
        </Card>
      </Col>
      {/* ------------------------mobileHidden  hidden in website   -------------------- */}
      <Col
        className="mobileHidden"
        style={{
          position: "sticky",
          top: 60,
          zIndex: "2",
          backgroundColor: "#fff",
        }}
        sm={12}
        xs={12}
        md={4}
      >
        <Card className="mb-2">
          <ListGroup variant="dark">
            <ListGroup.Item>
              TotalItems :
              <span
                style={{
                  color: "black",
                  float: "right",
                  fontSize: "1rem",
                  marginRight: "1rem",
                }}
              >
                {" "}
                {cartItems.reduce((acc, item) => acc + item.qty, 0)}
              </span>
            </ListGroup.Item>
            {/*  <ListGroup.Item>
              Shipping:{" "}
              <span
                style={{
                  color: "black",
                  float: "right",
                  fontSize: "1rem",
                  marginRight: "1rem",
                }}
              >
                {" "}
                ₹{cart.shippingPrice}
              </span>
            </ListGroup.Item> */}
            <ListGroup.Item>
              Total price:
              <span
                style={{
                  color: "black",
                  float: "right",
                  fontSize: "1rem",
                  marginRight: "1rem",
                }}
              >
                {" "}
                ₹{" "}
                {cartItems
                  .reduce((acc, item) => acc + item.qty * item.price, 0)
                  .toFixed(0)}
              </span>
            </ListGroup.Item>
            {cartTotalValue > 0 && (
              <ListGroup.Item>
                <Row>
                  <Col style={{ color: "green" }}>
                    Add items worth{" "}
                    <span style={{ color: "#000" }}>₹{cartTotalValue}</span> for
                    Free Delievry
                  </Col>
                </Row>
              </ListGroup.Item>
            )}
            <ListGroup.Item>
              Total Savings:
              <span
                style={{
                  color: "green",
                  float: "right",
                  fontSize: "1rem",
                  marginRight: "1rem",
                }}
              >
                ₹{" "}
                {cartItems
                  .reduce(
                    (acc, item) => acc + item.qty * (item.mrp - item.price),
                    0
                  )
                  .toFixed(0)}
              </span>
            </ListGroup.Item>

            <Button
              size="lg"
              type="button"
              variant="secondary"
              id="proceed"
              style={{ display: cartItems?.length === 0 ? "none" : "block" }}
              onClick={checkoutHandler}
            >
              Proceed
            </Button>
          </ListGroup>
        </Card>
      </Col>
      <Col className="mobileHidden" sm={12} xs={12} md={4}>
        {cartItems.map((item) => (
          <div key={item.product} style={{ maxWidth: "100%" }}>
            <Row className="g-1 border-bottom">
              <Col xs={3} style={{ display: "grid" }}>
                <img
                  src={item.image}
                  style={{ padding: "0.5rem" }}
                  className="img-fluid round-start fitImageCart"
                  alt={item.name}
                />
              </Col>
              <Col xs={9}>
                <div>
                  <p className="truncate">
                    {" "}
                    <Link to={`/product/${item.product}`}>
                      {item.name}
                    </Link>{" "}
                  </p>
                  <p>
                    <span style={{ color: "black" }}>
                      price: ₹ {item.price.toFixed(0)}
                    </span>

                    <select
                      style={{ marginLeft: "2rem" }}
                      className="form-select-sm "
                      aria-label="Default select example"
                      value={item.qty}
                      onChange={(e) =>
                        dispatch(
                          addToCart(item.product, Number(e.target.value))
                        )
                      }
                    >
                      {[...Array(item.countInStock).keys()]
                        .slice(0, 10)
                        .map((x) => (
                          <option key={x + 1} value={x + 1}>
                            {x + 1}
                          </option>
                        ))}
                    </select>
                    <button
                      style={{ marginRight: "2rem", float: "right" }}
                      id="delete"
                      type="button"
                      className="btn btn-sm "
                      onClick={() => removeFromCartHandler(item.product)}
                    >
                      <i className="fas fa-trash"></i>
                    </button>
                  </p>
                  <p>
                    Mrp:
                    <span style={{ textDecoration: "line-through" }}>
                      ₹ {item.mrp.toFixed(0)}
                    </span>
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        ))}
      </Col>
    </Row>
  );
};

export default CartScreen;
