import React, { useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Button, Row, Col, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Paginate from '../components/Paginate'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { listUsers, deleteUsers } from '../actions/userActions'
import { BiUser } from "react-icons/bi";
import { BsWhatsapp } from "react-icons/bs";


const UserListScreen = ({ history, match }) => {
  const pageNumber = match.params.pageNumber || 1;
  const dispatch = useDispatch()

  const userList = useSelector((state) => state.userList)
  const { loading, error, users, pages, page } = userList

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userDelete = useSelector((state) => state.userDelete)
  const { success: successDelete } = userDelete

  useEffect(() => {
    if (userInfo && userInfo.role.includes('superAdmin')) {
      dispatch(listUsers(pageNumber))
    } else {
      history.push('/login')
    }
  }, [dispatch, history, successDelete, userInfo, pageNumber])

  const deleteHandler = (id) => {
    if (window.confirm('Are You Sure')) {
      dispatch(deleteUsers(id))
    }
  }
  return (
    <>
      <h1>Users</h1>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='warning'>{error}</Message>
      ) : (
        <>
          <Row  >
            {users.map((user, index) => (
              <Col xs={12} sm={12} md={6} lg={4} xl={3} className='mx-auto d-flex justify-content-center'>
                <Card border="dark mb-3 mr-2" style={{ width: '18rem', padding: '0.3rem' }}>
                  <Card.Body>
                    <Card.Title>{(index + 1 * (24 * page + 1) - 24)}  <span style={{ color: '#000', marginLeft: '1rem' }}> <BiUser style={{ marginBottom: '0.5rem' }} size={30} /> <span style={{ marginLeft: '0.4rem' }}>{user.name} </span></span> </Card.Title>
                    <Card.Text >
                      Is Admin : <span style={{ marginLeft: '1rem' }}> {user.role.includes('admin') ? <i className='fas fa-check' style={{ color: 'green' }}></i> : <i className='fas fa-times' style={{ color: 'red' }}></i>}</span>
                    </Card.Text>
                    <Card.Text >
                    </Card.Text>
                    <Card.Text >
                      User_ID  :  {user._id}
                    </Card.Text>
                    phone :  <a href={`tel:${user.phone}`}>{user.phone}</a>
                    <Card.Text >
                      mail  :  <a href={`mailto:${user.email}`}>{user.email}</a>
                    </Card.Text>
                    <div>
                      <LinkContainer to={`/user/edit/${user._id}`}>
                        <Button variant='light' className='btn-md'>
                          Edit
                        </Button>
                      </LinkContainer>

                      <a href={`https://wa.me/${user.phone}/?text=${'hello'}`} target="_blank" rel="noreferrer" > <span style={{ marginLeft: '2rem' }}> <BsWhatsapp size={20} style={{ color: 'green' }} /> whats app  </span> </a>

                      <Button variant='light' style={{ color: 'red' }} className='btn-md d-flex float-end' onClick={() => deleteHandler(user._id)}>
                        Delete
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
          <Paginate pages={pages} page={page} userList={true} isAdmin={true} />
        </>
      )}
    </>
  )
}

export default UserListScreen
