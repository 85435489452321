import React from "react";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import Message from "../../components/Loader";
import Loader from "../../components/Loader";
import axios from "axios";

const MarketPlacesEdit = ({ match }) => {
  const marketID = match.params.id;
  const catgory = match.params.name;

  const [marketData, SetMarketData] = useState();

  /* const markets = useSelector((state) => state.marketPlaces);
	const { marketplaces, loading, error } = markets; */

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, loading, error } = userLogin;

  console.log(marketData);

  useEffect(() => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    axios
      .get(`/api/marketplace/${marketID}`, config)
      .then((res) => {
        let manifest = res.data;
        console.log(manifest);
        SetMarketData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [marketID, userInfo]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="warning">{error}</Message>
      ) : (
        <>
          <Table
            striped
            bordered
            hover
            size="sm"
            style={{ textAlign: "center" }}
            className="mt-3"
          >
            <thead>
              <tr>
                <th>#</th>
                <th>image</th>
                <th>Name</th>
              </tr>
            </thead>
            {marketData && (
              <tbody>
                {marketData.categories
                  .filter((category) => category.name === catgory)[0]
                  .subCategories?.map((subCategory, index) => (
                    <tr key={subCategory.id}>
                      <td>{index}</td>
                      <td>
                        <img
                          size={20}
                          style={{ height: "40px", width: "40px" }}
                          src={subCategory.image}
                          alt="img"
                        />
                      </td>
                      <td>{subCategory.name}</td>

                      {/* 		<td><input type="text" /></td> */}
                    </tr>
                  ))}
              </tbody>
            )}
          </Table>
        </>
      )}
    </>
  );
};

export default MarketPlacesEdit;
