import React, { useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { saleReport } from "../actions/orderActions";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export function RevenueReport({ history, orderType }) {
  const dispatch = useDispatch();
  const productSaleRt = useSelector((state) => state.productSaleReport);
  const { productSaleReport, loading, error } = productSaleRt;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (
      userInfo &&
      (userInfo.role.includes("admin") ||
        userInfo.role.includes("superAdmin") ||
        userInfo.role.includes("deliveryAdmin"))
    ) {
      dispatch(saleReport(orderType));
    } else {
      history.push("/login");
    }
  }, [dispatch, history, orderType, userInfo]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="warning">{error}</Message>
      ) : (
        <>
          <p className="DashboardHeading">Monthly Revenue</p>
          <Line
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: "top",
                },
              },
            }}
            data={{
              labels: productSaleReport.map((product) => product._id),
              datasets: [
                {
                  label: "Earnings",
                  data: productSaleReport.map(
                    (product) => product.totalProfit + product.totalShipping
                  ),
                  borderColor: "rgb(255, 193, 0)",
                  backgroundColor: "rgb(255, 193, 0)",
                  fill: false,
                  lineTension: 0.3,
                },
                {
                  label: "Cost",
                  data: productSaleReport.map((product) => product.orders * 10),
                  borderColor: "rgb(203, 255, 0)",
                  backgroundColor: "rgb(203, 255, 0)",
                  fill: false,
                  lineTension: 0.3,
                },
                {
                  label: "Profit",
                  data: productSaleReport.map(
                    (product) =>
                      product.totalProfit +
                      product.totalShipping -
                      product.orders * 10
                  ),
                  borderColor: "rgb(201, 210, 244)",
                  backgroundColor: "rgb(201, 210, 244)",
                  fill: false,
                  lineTension: 0.3,
                },
              ],
            }}
          />
        </>
      )}
    </>
  );
}
