// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging } from 'firebase/messaging';
import env from "dotenv";

env.config();
const {
  initializeAppCheck,
  ReCaptchaV3Provider,
} = require("firebase/app-check");
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional


const firebaseConfig = {
  apiKey: "AIzaSyB9HhQ9rvLvF6LErtM13mS_mZYKovVEBds",
  authDomain: "retail-center.firebaseapp.com",
  projectId: "retail-center",
  storageBucket: "retail-center.appspot.com",
  messagingSenderId: "377965025371",
  appId: "1:377965025371:web:8499b6334c5b8bdb72c77e",
  measurementId: "G-Q139CDK7LM"
};

export const app = initializeApp(firebaseConfig);

// Initialize Firebase
// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.


const siteKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY;
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(siteKey),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true,
});

console.log(appCheck);

export const messaging = getMessaging(app)
