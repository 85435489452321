import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Image,
  ListGroup,
  Card,
  Button,
  Form,
  Alert,
} from "react-bootstrap";
import Rating from "../components/Rating";
import Message from "../components/Message";
import Loader from "../components/Loader";
import Meta from "../components/Meta";
import {
  listProductDetails,
  createProductReview,
} from "../actions/productActions";
import { PRODUCT_CREATE_REVIEW_RESET } from "../constants/productConstants";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const ProductScreen = ({ history, match }) => {
  const [qty, setQty] = useState(1);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [message, setMessage] = useState(null);

  const dispatch = useDispatch();

  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const storeConfig = useSelector((state) => state.storeConfig);
  const { store } = storeConfig;

  const productReviewCreate = useSelector((state) => state.productReviewCreate);
  const {
    success: successProductReview,
    loading: loadingProductReview,
    error: errorProductReview,
  } = productReviewCreate;

  useEffect(() => {
    if (successProductReview) {
      setRating(0);
      setComment("");
    }
    if (!product._id || product._id !== match.params.id) {
      dispatch(listProductDetails(match.params.id));
      dispatch({ type: PRODUCT_CREATE_REVIEW_RESET });
    }
  }, [dispatch, match, successProductReview, product._id, error]);

  const addToCartHandler = () => {
    const cartPrice = cartItems.reduce(
      (acc, item) => acc + item.price * item.qty,
      0
    );

    const discountedProduct = store.discountedProducts.filter(
      (item) => item.id === product._id
    );

    if (discountedProduct.length > 0) {
      discountedProduct.map((item) => {
        if (cartPrice >= item.threshold && product._id === item.id) {
          console.log("Discounted Price");
          history.push(`/cart/${match.params.id}?qty=${qty}`);
        } else if (cartPrice < item.threshold && product._id === item.id) {
          setMessage(
            `Add more items worth ₹ ${item.threshold} to avail discount`
          );
        }
        return true;
      });
    } else {
      history.push(`/cart/${match.params.id}?qty=${qty}`);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      createProductReview(match.params.id, {
        rating,
        comment,
      })
    );
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          {message ? (
            <Alert className="alertMessage" variant="danger">
              {message}
              <Link to={"/"}> Continue Shopping</Link>
            </Alert>
          ) : null}
          <Meta title={product.name} />
          <Row>
            <Col xs={12} md={4} lg={4} xl={3} /* className='zoom' */>
              <Carousel
                showArrows={true}
                showThumbs={true}
                showStatus={true}
                infiniteLoop={true}
                autoPlay={true}
                interval={3000}
                onClickItem={true}
                onClickThumb={true}
              >
                {product.images.map((image) => (
                  <div key={image}>
                    <Image renderThumbs src={image} alt={product.name} fluid />
                  </div>
                ))}
              </Carousel>
            </Col>
            <Col xs={12} md={4} xl={5}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <h3>{product.name}</h3>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Rating
                    value={product.rating}
                    text={`  ${product.rating} `}
                  />
                  <span className="ml-2">{product.numReviews} reviews</span>
                </ListGroup.Item>

                <ListGroup.Item>
                  <div className="productPrice">₹ {product.price}</div>
                  <div className="productMrp">
                    ₹ <s> {product.mrp}</s>
                  </div>
                  <div className="productOffer">
                    {(
                      ((product.mrp - product.price) / product.mrp) *
                      100
                    ).toFixed(0)}
                    % off
                  </div>
                </ListGroup.Item>
                <span>Description :</span>
                <ListGroup.Item>{product.description}</ListGroup.Item>
              </ListGroup>
            </Col>
            <Col xs={12} md={4}>
              <Card>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <Row>
                      <Col>Price:</Col>
                      <Col>
                        <p style={{ fontWeight: "600", whiteSpace: "normal" }}>
                          ₹ {product.price * qty}
                        </p>
                      </Col>
                    </Row>
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <Row>
                      <Col>Status:</Col>
                      <Col>
                        {product.countInStock > 0 ? "In Stock" : "Out Of Stock"}
                      </Col>
                    </Row>
                  </ListGroup.Item>

                  {product.countInStock > 0 && (
                    <ListGroup.Item>
                      <Row>
                        <Col>Qty</Col>
                        <Col>
                          <Form.Control
                            as="select"
                            value={qty}
                            onChange={(e) => setQty(e.target.value)}
                          >
                            {[...Array(product.countInStock).keys()]
                              .slice(0, 10)
                              .map((x) => (
                                <option key={x + 1} value={x + 1}>
                                  {x + 1}
                                </option>
                              ))}
                          </Form.Control>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  )}

                  {product.countInStock >= 1 ? (
                    <ListGroup.Item>
                      <Button
                        onClick={addToCartHandler}
                        size="lg"
                        id={"add-to-cart"}
                        name={"add-to-cart"}
                        variant="secondary"
                        type="button"
                        disabled={product.countInStock === 0}
                      >
                        Add To Cart
                      </Button>
                    </ListGroup.Item>
                  ) : (
                    <ListGroup.Item>Out of Stock</ListGroup.Item>
                  )}
                </ListGroup>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="mb-4">
              <h2>Reviews</h2>
              {product.reviews.length === 0 && <Message>No Reviews</Message>}
              <ListGroup variant="flush">
                {product.reviews.map((review) => (
                  <ListGroup.Item key={review._id}>
                    <strong>{review.name}</strong>
                    <Rating value={review.rating} />
                    <p>{review.createdAt.substring(0, 10)}</p>
                    <p>{review.comment}</p>
                  </ListGroup.Item>
                ))}
                <ListGroup.Item>
                  <h2>Write a Customer Review</h2>
                  {successProductReview && (
                    <Message variant="success">
                      Review submitted successfully
                    </Message>
                  )}
                  {loadingProductReview && <Loader />}
                  {errorProductReview && (
                    <Message variant="danger">{errorProductReview}</Message>
                  )}
                  {userInfo ? (
                    <Form onSubmit={submitHandler}>
                      <Form.Group controlId="rating">
                        <Form.Label>Rating</Form.Label>
                        <Form.Control
                          as="select"
                          value={rating}
                          onChange={(e) => setRating(e.target.value)}
                        >
                          <option value="">Select...</option>
                          <option value="1">1 - Poor</option>
                          <option value="2">2 - Fair</option>
                          <option value="3">3 - Good</option>
                          <option value="4">4 - Very Good</option>
                          <option value="5">5 - Excellent</option>
                        </Form.Control>
                      </Form.Group>
                      <Form.Group controlId="comment">
                        <Form.Label>Comment</Form.Label>
                        <Form.Control
                          as="textarea"
                          row="3"
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                      <Button
                        className="mt-3"
                        disabled={loadingProductReview}
                        type="submit"
                        variant="secondary"
                      >
                        Submit
                      </Button>
                    </Form>
                  ) : (
                    <Message>
                      Please <Link to="/login">sign in</Link> to write a review{" "}
                    </Message>
                  )}
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default ProductScreen;
