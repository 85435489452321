import { React, useState } from "react";
import { Pagination } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const PaginateOrders = ({
  pages,
  page,
  orderStatus = "new",
  isAdmin = false,
}) => {
  function pageLink(pageNumber) {
    if (!isAdmin) {
      return `/admin/orderlist/${orderStatus}/${pageNumber}`;
    } else {
      return `/admin/orderlist/${orderStatus}/${pageNumber}`;
    }
  }

  const [pageNumber, setPageNumber] = useState(page);

  const handlePageChange = (pageNumber) => {
    if (pageNumber <= pages && pageNumber > 0) {
      setPageNumber(pageNumber);
    }
  };

  // Corrected logic for start and end
  let start = Math.max(0, page - 3); // Ensure start is not negative
  let end = Math.min(pages, start + 7); // Adjust end based on start

  return (
    pages > 1 && (
      <Pagination>
        <LinkContainer to={pageLink(1)}>
          <Pagination.First onClick={() => handlePageChange(1)}>
            {" "}
            First
          </Pagination.First>
        </LinkContainer>
        {/* previous number in  pagination */}
        <LinkContainer
          to={pageLink(pageNumber - 1 >= 1 ? pageNumber - 1 : page)}
        >
          <Pagination.Prev onClick={() => handlePageChange(pageNumber - 1)} />
        </LinkContainer>
        {/* showing few numbers in pagination */}
        {[...Array(pages).keys()].slice(start, end).map((x) => (
          <LinkContainer key={x + 1} to={pageLink(x + 1)}>
            <Pagination.Item active={x + 1 === page}>{x + 1}</Pagination.Item>
          </LinkContainer>
        ))}
        {/* next number in  pagination */}
        <LinkContainer
          to={pageLink(pageNumber + 1 < pages ? pageNumber + 1 : pages)}
        >
          <Pagination.Next onClick={() => handlePageChange(pageNumber + 1)} />
        </LinkContainer>
        {/* Direct Last number in  pagination */}

        <LinkContainer to={pageLink(pages)}>
          <Pagination.Last onClick={() => handlePageChange(pages)}>
            Last
          </Pagination.Last>
        </LinkContainer>
      </Pagination>
    )
  );
};

export default PaginateOrders;