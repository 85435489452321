import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Product from "../components/Product";
import Message from "../components/Message";
import Loader from "../components/Loader";
import Paginate from "../components/Paginate";
import { listNewArrivals, listProducts } from "../actions/productActions";
import ProductCarousel from "../components/ProductCarousel";
import Meta from "../components/Meta";
import Category from "../components/Category";
import { Route } from "react-router-dom";
import NewArrivals from "../components/slider/NewArrivals";
//import TopRated from "../components/slider/TopRated";
import TopSelling from "../components/slider/TopSelling";
import TopDeals from "../components/slider/TopDeals";
import SubCategory from "../components/SubCategory";
import { getStoreConfig } from "../actions/storeActions";
import { getToken } from "firebase/messaging";
import { messaging } from "./Firebase";
import Brand from "../components/Brand";
import axios from "axios";
import env from "dotenv";
import Store from "../components/Store";
import BundleScreen from "./BundleScreen";

env.config();

// read pincode from saved address in localstorage
// if not found then read from .env file

const Homescreen = ({ match, history }) => {
  const keyword = match.params.keyword;
  const category = match.params.category;
  const subCategory = match.params.subcategory;
  const brand = match.params.brand;
  const storeId = match.params.storeId;
  const pageNumber = match.params.pageNumber || 1;
  const dispatch = useDispatch();

  const [pinCode, setPincode] = useState("");

  const productList = useSelector((state) => state.productList);
  const { loading, error, products, pages, page } = productList;

  const storeConfig = useSelector((state) => state.storeConfig);

  const { store } = storeConfig;
  document.title = store.name;

  async function requestPermission() {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      // Generate Token
      const token = await getToken(messaging, {
        vapidKey:
          "BKlTnxgXH4V73TaFgRQwzsn6oj25eWcsMRbKL4NmviKBW-aYEoiMmvPsFW5PlGvNA3zvepWFIRdeL__cL_JqEvY",
      });
      // Sending this token  to server ( db)
      axios.post(`/api/notification/store-token`, { token: token });
      console.log("Token Gen", token);
    } else if (permission === "denied") {
      console.log("Notification Permission Denied");
    }
  }

  useEffect(() => {
    const shippingAddress = localStorage.getItem("marketPlaceAddress")
      ? JSON.parse(localStorage.getItem("marketPlaceAddress"))
      : {};

    if (shippingAddress.marketPinCode) {
      setPincode(shippingAddress.marketPinCode);
    } else {
      history.push("/location");
    }

    console.log("shippingAddress", shippingAddress);
    if (!store._id) {
      if (pinCode) {
        dispatch(getStoreConfig(pinCode));
      }
    }
    if (store._id && (keyword || category || subCategory || brand || storeId)) {
      dispatch(
        listProducts(
          keyword,
          category,
          subCategory,
          pageNumber,
          store._id,
          brand,
          storeId
        )
      );
    } else if (store._id) {
      dispatch(listNewArrivals(store._id));
    }
    requestPermission();
  }, [
    dispatch,
    keyword,
    category,
    subCategory,
    brand,
    pageNumber,
    store._id,
    history,
    pinCode,
    storeId,
  ]);

  return (
    <>
      {store.categories && (
        <>
          {!subCategory && !keyword && !category && !brand && !storeId && (
            <div className="category">
              {store.categories
                .filter((category) => category.name !== "Please Select")
                .filter((category) => category.display !== false)
                .map((category) => (
                  <div key={category.id}>
                    <Route
                      render={({ history }) => (
                        <Category history={history} category={category} />
                      )}
                    />
                  </div>
                ))}
            </div>
          )}
          {store.categories
            .filter((category1) => category1.name === category)
            .map((category) => (
              <div className="sub-category">
                {category.subCategories.map((subCategory) => (
                  <div key={subCategory.id}>
                    <Route
                      render={({ history }) => (
                        <SubCategory
                          history={history}
                          subCategory={subCategory}
                        />
                      )}
                    />
                  </div>
                ))}
              </div>
            ))}
        </>
      )}
      <Meta />
      {store.bannerImages && (
        <>
          {!keyword && !category && !subCategory && !brand && !storeId && (
            <ProductCarousel banners={store.bannerImages} />
          )}
        </>
      )}
      {!brand && !keyword && !category && !subCategory && !storeId && (
        <Route render={({ history }) => <BundleScreen history={history} />} />
      )}
      {!keyword && !category && !subCategory && !brand && !storeId && (
        <TopDeals error={error} loading={loading} />
      )}
      {store.stores && !keyword && !category && !subCategory && !brand && (
        <>
          <h1 className="carouselHeading"> SHOPS </h1>
          <div className="category">
            {store.stores
              .filter((store1) => store1.name !== "sample store")
              .map((store) => (
                <div key={store.id}>
                  <Route
                    render={({ history }) => (
                      <Store history={history} store={store} />
                    )}
                  />
                </div>
              ))}
          </div>
        </>
      )}
      {!keyword && !category && !subCategory && !brand && !storeId && (
        <TopSelling />
      )}

      {store.brands && !keyword && !category && !subCategory && !storeId && (
        <>
          <h1 className="carouselHeading"> SHOP BY BRANDS </h1>

          <div className="category">
            {store.brands
              .filter((brand1) => brand1.name !== "sample brand")
              .map((brand) => (
                <div key={brand.id}>
                  <Route
                    render={({ history }) => (
                      <Brand history={history} brand={brand} />
                    )}
                  />
                </div>
              ))}
          </div>
        </>
      )}
      {!keyword && !category && !subCategory && !brand && !storeId && (
        <NewArrivals error={error} loading={loading} products={products} />
      )}

      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          {(keyword || category || subCategory || brand || storeId) && (
            <>
              <Row>
                {products.map((product) => (
                  <Col key={product._id} xs={6} sm={6} md={3} lg={3} xl={2}>
                    <Product product={product} id="product" />
                  </Col>
                ))}
              </Row>
            </>
          )}
          {/* {!keyword && !category && !subCategory && <TopRated />} */}
          {(keyword || category || subCategory || brand || storeId) && (
            <Paginate
              pages={pages}
              page={page}
              category={category ? category : ""}
              subCategory={subCategory ? subCategory : ""}
              keyword={keyword ? keyword : ""}
              brand={brand ? brand : ""}
              storeId={storeId ? storeId : ""}
            />
          )}
        </>
      )}
      <br />
    </>
  );
};

export default Homescreen;
