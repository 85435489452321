import React, { useState, useEffect } from "react";
import { Form, Button, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import FormContainer from "../components/FormContainer";
import { saveShippingAddress } from "../actions/cartActions";
import CheckoutSteps from "../components/CheckoutSteps";
import UseGeolocation from "../components/UseGeolocation";
import GoogleMapReact from "google-map-react";
import { IoLocationSharp } from "react-icons/io5";

import axios from "axios";
import env from "dotenv";

env.config();

const ShippingScreen = ({ history }) => {
  /* const cart = useSelector((state) => state.cart)
  const { shippingAddress } = cart */
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [detectedAddress, setDetectedAddress] = useState();
  const [pinCode, setPinCode] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState(userInfo.address);

  const location = UseGeolocation();

  const phone = userInfo.phoneNumber;

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      !location.error &&
      location.loaded &&
      location.coordinates.lat !== "" &&
      location.coordinates.lng !== ""
    ) {
      const option = {
        method: "POST",
        url: `https://maps.googleapis.com/maps/api/geocode/json?latlng=${location.coordinates.lat},${location.coordinates.lng}&key=${process.env.REACT_APP_GEO_LOCATION_API_KEY}`,
      };

      axios
        .request(option)
        .then((response) => {
          const detectedAddress = response.data.results[0].formatted_address;
          setDetectedAddress(detectedAddress);
          setPinCode(
            response.data.results[0].address_components[
              response.data.results[0].address_components.length - 1
            ].long_name
          );
        })
        .catch(function (error) {
          console.error(error);
        });
    }
    // eslint-disable-next-line
  }, [location.loaded, location.error]);

  /* .coordinates.lat,
  location.coordinates.lng,
  location.loaded,
  location.error, */
  let address = deliveryAddress + ": " + detectedAddress;

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(saveShippingAddress({ phone, address, pinCode, location }));
    history.push("/payment");
  };

  const defaultProps = {
    center: {
      lat: location.coordinates.lat,
      lng: location.coordinates.lng,
    },
    zoom: 15,
  };

  const AnyReactComponent = ({ text }) => (
    <div>
      {" "}
      <IoLocationSharp style={{ color: "red" }} size={22} />{" "}
      <span style={{ color: "#000", fontWeight: "600", display: "flex" }}>
        {text}
      </span>{" "}
    </div>
  );

  return (
    <>
      <FormContainer className={"col-xs-12 col-sm-12"}>
        <CheckoutSteps step1 step2 />
        <h1>Shipping</h1>
        <Form onSubmit={submitHandler}>
          <Form.Group controlId="address">
            <Form.Label>Delivery Address</Form.Label>

            <Form.Control
              type="text"
              name="address-one"
              placeholder="H/NO: with street Address"
              value={deliveryAddress}
              onChange={(e) => {
                setDeliveryAddress(e.target.value);
              }}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId="pinCode">
            <Form.Label>Pin Code </Form.Label>
            <Form.Control
              autoComplete="postal-code"
              name="postal-code"
              type="text"
              placeholder="Enter Your Pin Code"
              value={pinCode}
              onChange={(e) => setPinCode(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <br />
          <Button size="lg" type="submit" id="payment" variant="secondary">
            Continue To Payment
          </Button>
        </Form>
        <Row className="d-flex justify-content-center mb-4 mt-2" xl={12}>
          {location.coordinates.lat && location.coordinates.lng && (
            <div style={{ height: "22em", width: "22em" }}>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: `${process.env.REACT_APP_GEO_LOCATION_API_KEY}`,
                }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
              >
                <AnyReactComponent
                  lat={location.coordinates.lat}
                  lng={location.coordinates.lng}
                  style={{ color: "red" }}
                  text="your Current Location"
                >
                  {" "}
                  location
                </AnyReactComponent>
              </GoogleMapReact>
            </div>
          )}
        </Row>
      </FormContainer>
    </>
  );
};

export default ShippingScreen;
