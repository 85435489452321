import React from "react";
import { Row, Col, Image, Container } from "react-bootstrap";

const Store = ({ history, store }) => {
  const submitHandler = (e) => {
    e.preventDefault();
    if (store.name.trim()) {
      history.push(`/store/${store.id}`);
    } else {
      history.push("/");
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col
          onClick={submitHandler}
          className="text-center align-items-center zoom"
        >
          <Image
            style={{ maxWidth: "100%", maxHeight: "100%" }}
            src={store.image}
            alt="img"
            className="fluid"
            variant="top"
          />
          <div className="category-btn">
            <button className="btn" type="submit">
              {store.name}
            </button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Store;
