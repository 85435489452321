import React from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const DeliveryAdminHeader = ({ store, logoutHandler, userInfo }) => {
  return (
    <header
      className="headerShadow"
      style={{ position: "sticky", top: "0", zIndex: "2" }}
    >
      <Navbar
        bg="dark"
        className="navbar-fixed-top"
        variant="dark"
        expand="md"
        collapseOnSelect
      >
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand>
              <i className="fas fa-store"></i>
              {store.name}
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className=" mr-auto">
              <LinkContainer to="/admin/dashboard">
                <Nav.Link>
                  <i className="fas fa-tachometer-alt"></i> Dashboard
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to="/admin/productlist">
                <Nav.Link>
                  <i className="fas fa-shopping-basket"></i> Products
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to="/admin/orderlist">
                <Nav.Link>
                  <i className="fas fa-th-list"></i> Orders
                </Nav.Link>
              </LinkContainer>
            </Nav>
            <Nav>
              {userInfo ? (
                <NavDropdown title={userInfo.name} id="username">
                  <LinkContainer to="/profile">
                    <NavDropdown.Item>
                      <i className="fas fa-user-circle"></i> profile
                    </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/myorders" id="myorders">
                    <NavDropdown.Item>
                      <i className="fas fa-list"></i> My Orders
                    </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/login" id="LogOut">
                    <NavDropdown.Item onClick={logoutHandler}>
                      <i className="fas fa-sign-out-alt"></i> Logout
                    </NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              ) : (
                <LinkContainer to="/login" id="Login">
                  <Nav.Link>
                    <i className="fas fa-user"></i>Sign In
                  </Nav.Link>
                </LinkContainer>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default DeliveryAdminHeader;
