import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { saveMarketPlaceAddress } from "../actions/cartActions";
import { MdLocationPin } from "react-icons/md";
import { getMarketPlaceId } from "../config";
import axios from "axios";
import env from "dotenv";

env.config();

const LocationScreen = ({ history }) => {
  const [detectedAddress, setDetectedAddress] = useState();
  const [marketPinCode, setMarketPinCode] = useState("585412");

  /*  const getMarketPlaceId = async (pinCode) => {
    const res = await axios.get(`/api/marketplace/places/${pinCode}`);
    const marketplaceId = res.data[0]._id;
    console.log("get from pinCode : " + JSON.stringify(res.data[0]._id));
    return marketplaceId;
  }; */

  const onSuccess = (position) => {
    if (position.coords.latitude !== "" && position.coords.longitude !== "") {
      console.log(position.coords.latitude, position.coords.longitude);
      const option = {
        method: "POST",
        url: `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${process.env.REACT_APP_GEO_LOCATION_API_KEY}`,
      };

      axios
        .request(option)
        .then((response) => {
          const detectedAddress = response.data.results[0].formatted_address;
          setDetectedAddress(detectedAddress);
          const pinCode =
            response.data.results[0].address_components[
              response.data.results[0].address_components.length - 1
            ].long_name;
          setMarketPinCode(pinCode);
          console.log(pinCode);
          dispatch(saveMarketPlaceAddress({ address, marketPinCode }));
          history.push("/");
        })
        .catch(function (error) {
          console.error(error);
        });
    }
  };

  const onError = (error) => {
    console.log(error);
  };

  if (!("geolocation" in navigator)) {
    onError({
      code: 0,
      message: "Geolocation is not supported by this browser",
    });
  }
  const dispatch = useDispatch();
  let address = detectedAddress;

  const submitHandler = async (e) => {
    e.preventDefault();

    const marketplaceId = await getMarketPlaceId(marketPinCode);
    dispatch(saveMarketPlaceAddress({ address, marketPinCode, marketplaceId }));
    if (marketplaceId === "undefined" || marketplaceId === undefined) {
      history.push("/notinservice");
    } else {
      history.push("/");
    }
  };

  const detectHandler = (e) => {
    e.preventDefault();
    navigator.geolocation.getCurrentPosition(onSuccess, onError, {
      maximumAge: 60000,
      timeout: 5000,
      enableHighAccuracy: true,
    });
  };

  return (
    <>
      <Row className="mt-5 d-flex align-items-center justify-content-center">
        <Col className="" xl={4} lg={4} md={6} sm={6} xs={6}>
          <Form className="col-md-12 col-sm-12" onSubmit={submitHandler}>
            <Form.Group controlId="pinCode">
              <Form.Control
                autoComplete="postal-code"
                name="postal-code"
                type="number"
                required
                placeholder="Enter Your Pin Code"
                value={marketPinCode}
                onChange={(e) => setMarketPinCode(e.target.value)}
              ></Form.Control>
            </Form.Group>{" "}
          </Form>{" "}
        </Col>
        <Col xl={4} lg={4} md={6} sm={6} xs={6}>
          <div>
            <span
              onClick={submitHandler}
              className="btn btn-secondary d-block"
              type="submit"
            >
              Submit
            </span>
          </div>
        </Col>
      </Row>
      <Row className="mt-5 mb-5">
        <Col className="d-flex align-items-center justify-content-center">
          <span
            style={{ color: "#000", fontWeight: "600", fontSize: "1.2rem" }}
          >
            OR
          </span>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex align-items-center justify-content-center">
          <span onClick={detectHandler} className="btn btn-secondary btn-lg">
            <span>
              <MdLocationPin size={20} />
            </span>{" "}
            Detect My Pincode
          </span>
        </Col>
      </Row>
    </>
  );
};

export default LocationScreen;
