import { React, useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { MARKETPLACE_ID } from "../config";
import ProductCard from "./ProductCard";
import axios from "axios";
import env from "dotenv";

env.config();
const Bundle = ({ bundle, history }) => {
  const [productsdetails, setProducts] = useState();
  const addToCartHandler = () => {
    history.push(`/cart/${bundle._id}?qty=1&type=bundle`);
  };

  console.log(bundle._id);

  useEffect(() => {
    const getBundle = async () => {
      const marketplaceId = MARKETPLACE_ID;
      const productsdetails = await bundle.products.reduce(
        async (acc, product) => {
          const { data } = await axios.get(
            `/api/marketplace/${marketplaceId}/products/${product}`
          );
          return [...(await acc), data];
        },
        []
      );

      setProducts(productsdetails);
    };
    getBundle();
  }, [bundle.products]);

  return (
    <div style={{ margin: "0.2rem" }}>
      <div className="d-flex mb-1">
        {productsdetails && (
          <h5 style={{ margin: "0", color: "black", float: "left" }}>
            {`${bundle.name}  ${productsdetails.length}  items
            For ₹
            ${productsdetails
              .reduce((acc, item) => acc + item.price, 0)
              .toFixed(0)}`}
          </h5>
        )}{" "}
        <Button
          style={{ marginLeft: "auto", float: "right" }}
          variant="secondary"
          onClick={addToCartHandler}
        >
          Add all To Cart
        </Button>
      </div>
      {productsdetails && (
        <Row>
          {productsdetails.map((product, index) => (
            <Col key={index} xs={4} sm={6} md={2} lg={2} xl={2}>
              <ProductCard product={product} id="product" />
            </Col>
          ))}
        </Row>
      )}

      {productsdetails && productsdetails.length >= 12 && (
        <div className="d-flex mb-1 mt-2">
          {productsdetails && (
            <h5 style={{ margin: "0", color: "black", float: "left" }}>
              {`${bundle.name}  ${productsdetails.length}  items
            For ₹
            ${productsdetails
              .reduce((acc, item) => acc + item.price, 0)
              .toFixed(0)}`}
            </h5>
          )}{" "}
          <Button
            style={{ marginLeft: "auto", float: "right" }}
            variant="secondary"
            onClick={addToCartHandler}
          >
            Add all To Cart
          </Button>
        </div>
      )}

      <div class="wrapper">
        <div class="divider div-transparent"></div>
      </div>
    </div>
  );
};

export default Bundle;
