import axios from "axios";
import env from "dotenv";
import {
	MARKET_PLACE_CONFIG_REQUEST,
	MARKET_PLACE_CONFIG_SUCCESS,
	MARKET_PLACE_CONFIG_FAIL,
	MARKET_PLACE_UPDATE_REQUEST,
	MARKET_PLACE_UPDATE_SUCCESS,
	MARKET_PLACE_UPDATE_FAIL,
} from "../constants/marketplaceConstants";
env.config();
axios.defaults.baseURL = process.env.REACT_APP_API_SERVER;
/* const MarketId = process.env.REACT_APP_MARKETPLACE_ID; */

export const getAllMarketPlaces = () => async (dispatch, getState) => {
	try {
		dispatch({
			type: MARKET_PLACE_CONFIG_REQUEST,
		});
		const {
			userLogin: { userInfo },
		} = getState();

		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${userInfo.token}`,
			},
		};
		const { data } = await axios.get(`/api/marketplace`, config);

		dispatch({
			type: MARKET_PLACE_CONFIG_SUCCESS,
			payload: data,
		});

	} catch (error) {

		dispatch({
			type: MARKET_PLACE_CONFIG_FAIL,
			payload: error.response.data.message
				? error.response.data.message
				: error.message,

		});

	}
};


export const updateMarketPlaces = (market) => async (dispatch, getState) => {
	try {
		dispatch({
			type: MARKET_PLACE_UPDATE_REQUEST,
		});

		const {
			userLogin: { userInfo },
		} = getState();

		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${userInfo.token}`,
			},
		};
		const { data } = await axios.put(
			`/api/marketplace/${market._id}`,
			market,
			config
		);
		dispatch({
			type: MARKET_PLACE_UPDATE_SUCCESS,
			payload: data,
		});
	} catch (error) {

		dispatch(
			{
				type: MARKET_PLACE_UPDATE_FAIL,
				payload: error.response.data.message
					? error.response.data.message
					: error.message,
			}
		);
	}
};
