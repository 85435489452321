import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Form, Button, } from 'react-bootstrap';
import { useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import axios from 'axios'
/*	import env from 'dotenv' */


/* env.config()
axios.defaults.baseURL = process.env.REACT_APP_API_SERVER
const store = process.env.REACT_APP_STORE_ID */
const OnBoardStoreScreen = ({ history }) => {



	const userLogin = useSelector((state) => state.userLogin)
	const { userInfo } = userLogin

	const productDetails = useSelector((state) => state.productDetails)
	const { loading, error } = productDetails


	const [name, setName] = useState('')
	const [address, setAddress] = useState('')
	const [city, setCity] = useState('')
	const [company, setCompany] = useState('')
	const [country, setCountry] = useState('')
	const [pinCode, setPinCode] = useState('')
	const [gst, setGst] = useState('')
	const [logo, setLogo] = useState('')


	useEffect(() => {
		if (!userInfo) {
			history.push('/login?redirect=onboard')
		}
	})



	const submitHandler = (e) => {
		e.preventDefault()
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const data = {
			'name': name,
			'logo': logo,
			'address': address,
			'company': company,
			'pinCode': pinCode,
			'city': city,
			'country': country,
			'gstNumber': gst
		}
		axios
			.post(`/api/config`, data, config)
			.catch((err) => {
				console.log(err)
			})
		clearImmediate();
	}

	return (
		<div>
			{loading ? (
				<Loader />
			) : error ? (
				<Message variant='warning'>{error}</Message>
			) : (
				<>
					<Button variant='secondary'>
						<MdOutlineArrowBackIosNew />
						<Link to='/' style={{ color: "white" }}> Home</Link>
					</Button>
					<Form onSubmit={submitHandler}>
						<Row className="mb-1">
							<Col>
								<h4 style={heading}> On Boardding Process</h4>
								<Col >
									<Form.Group className="mb-3" controlId="number">
										<Form.Label>Mobile Number</Form.Label>
										<Form.Control placeholder="Enter Your Number" value={name} onChange={(e) => setName(e.target.value)} />
									</Form.Group>
								</Col>
								<Col >
									<Form.Group className="mb-3" controlId="address">
										<Form.Label>Address</Form.Label>
										<Form.Control placeholder="Enter Your Address" value={address} onChange={(e) => setAddress(e.target.value)} />
									</Form.Group>
								</Col>
								<Col>
									<Form.Group className="mb-3" controlId="City">
										<Form.Label>City</Form.Label>
										<Form.Control type='text' placeholder="Enter City" value={city} onChange={(e) => setCity(e.target.value)} />
									</Form.Group>
								</Col>
								<Col>
									<Form.Group className="mb-3" controlId="Company">
										<Form.Label>Company</Form.Label>
										<Form.Control type='text' placeholder=" Enter Your Company Name" value={company} onChange={(e) => setCompany(e.target.value)} />
									</Form.Group>
								</Col>
								<Col>
									<Form.Group className="mb-3" controlId="Country">
										<Form.Label>Country</Form.Label>
										<Form.Control type='text' placeholder="Enter Country" value={country} onChange={(e) => setCountry(e.target.value)} />
									</Form.Group>
								</Col>
								<Col>
									<Form.Group className="mb-3" controlId="PinCode">
										<Form.Label>PinCode</Form.Label>
										<Form.Control type='text' placeholder="Enter PinCode" value={pinCode} onChange={(e) => setPinCode(e.target.value)} />
									</Form.Group>
								</Col>
								<Col>
									<Form.Group className="mb-3" controlId="gstNumber">
										<Form.Label>GST Number</Form.Label>
										<Form.Control type='text' placeholder="Enter GST Number" value={gst} onChange={(e) => setGst(e.target.value)} />
									</Form.Group>
								</Col>
								<Col>
									<Form.Group className="mb-3" controlId="Logo">
										<Form.Label>Logo</Form.Label>
										<Form.Control type='text' placeholder="Enter Logo" value={logo} onChange={(e) => setLogo(e.target.value)} />
									</Form.Group>
								</Col>
							</Col>
						</Row>
						<Button variant="dark" type="submit">
							Submit
						</Button>
					</Form>
				</>
			)
			}

		</div >
	)
}

export default OnBoardStoreScreen

const heading = {
	textAlign: 'center',
	color: '#000'
}