import React from "react";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import easyinvoice from "easyinvoice";

const GstBillingInvoice = () => {
  const orderDetails = useSelector((state) => state.orderDetails);
  const { order } = orderDetails;

  /* ------------company Details---------- */
  const company = order?.store.company;
  const companyAddress = order?.store?.address;
  const companyCode = order?.store?.gstNumber;

  /* --------------client Details----------- */
  const clientName = order?.user?.name;
  const ClientAdress = order?.shippingAddress?.address;

  /* -------------order information------------ */
  const infoId = order._id;
  const date = new Date();
  const infoDate =
    date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();

  let products = [];

  order.orderItems.map((item) => {
    let quantity = item.qty;
    let tax = item.tax;
    let price = (item.price * 100) / (100 + tax);
    console.log(item.category);

    products.push({
      quantity: quantity,
      description: item.name,
      price: price.toFixed(2),
      "tax-rate": `${tax}`,
    });
    return 0;
  });

  /* var html = '<p style="color:green">Hello world! This is invoice number %number%</p>' */

  var data = {
    /*  customize: {
      // btoa === base64 encode
      template: btoa(html), // Your template must be base64 encoded
    },*/
    images: {
      logo: "https://public.easyinvoice.cloud/img/logo_en_original.png",
    },
    sender: {
      company: company,
      address: companyAddress,
      zip: companyCode,
    },
    client: {
      company: clientName,
      address: ClientAdress,
    },

    information: {
      number: infoId,
      date: infoDate,
    },
    products: products,
    "bottom-notice": "Thank you for Shopping",
    settings: {
      currency: "INR",
      "tax-notation": "GST",
      "margin-top": 25,
      "margin-right": 25,
      "margin-left": 25,
      "margin-bottom": 10,
    },
  };

  const downloadInvoice = async () => {
    const datas = data;
    easyinvoice.createInvoice(datas, function (result) {
      easyinvoice.download("myInvoice.pdf", result.pdf);
    });
  };

  return (
    <>
      <Button
        className="textSizeM"
        variant="secondary"
        onClick={downloadInvoice}
      >
        download GST Invoice
      </Button>
    </>
  );
};

export default GstBillingInvoice;
