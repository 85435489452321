import React, { useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { saleReport } from "../actions/orderActions";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export function SaleReportChart({ history, orderType }) {
  const dispatch = useDispatch();
  const productSaleRt = useSelector((state) => state.productSaleReport);
  const { productSaleReport, loading, error } = productSaleRt;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (
      userInfo &&
      (userInfo.role.includes("admin") ||
        userInfo.role.includes("superAdmin") ||
        userInfo.role.includes("deliveryAdmin"))
    ) {
      dispatch(saleReport(orderType));
    } else {
      history.push("/login");
    }
  }, [dispatch, history, orderType, userInfo]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="warning">{error}</Message>
      ) : (
        <>
          <p className="DashboardHeading">Monthly Sales</p>
          <Line
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: "top",
                },
              },
            }}
            data={{
              labels: productSaleReport.map((product) => product._id),
              datasets: [
                {
                  label: "Sales",
                  data: productSaleReport.map((product) => product.totalPrice),
                  borderColor: "rgb(255, 99, 132)",
                  backgroundColor: "rgba(255, 99, 132, 0.5)",
                  fill: false,
                  lineTension: 0.3,
                },
              ],
            }}
          />
        </>
      )}
    </>
  );
}
