import React from "react";
import { Row, Col, Image, Container } from "react-bootstrap";

const Brand = ({ history, brand }) => {
  const submitHandler = (e) => {
    e.preventDefault();
    if (brand.name.trim()) {
      history.push(`/brand/${brand.name}`);
    } else {
      history.push("/");
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col
          onClick={submitHandler}
          className="text-center align-items-center zoom"
        >
          <Image
            style={{ maxWidth: "100%", maxHeight: "100%" }}
            src={brand.image}
            alt="img"
            className="fluid"
            variant="top"
          />
          <div className="category-btn">
            <button className="btn" type="submit">
              {brand.name}
            </button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Brand;
