import React, { useState, useEffect } from "react";
import { MARKETPLACE_ID } from "../config";
import axios from "axios";
import env from "dotenv";
import { Link } from "react-router-dom";
import {
  Button,
  Row,
  Col,
  ListGroup,
  Image,
  Card,
  Modal,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import CheckoutSteps from "../components/CheckoutSteps";
import { createOrder } from "../actions/orderActions";
import { ORDER_CREATE_RESET } from "../constants/orderConstants";
import { USER_DETAILS_RESET } from "../constants/userConstants";
import { MdOutlineCheckCircle } from "react-icons/md";

env.config();
const PlaceOrderScreen = ({ history }) => {
  const marketplaceId = MARKETPLACE_ID;

  const dispatch = useDispatch();
  const [razorPayLoading, setRazorPayLoading] = useState(false);
  const [razorPayOrderId, setRazorPayOrderId] = useState(null);
  const [razorPayKey, setRazorPayKey] = useState(null);
  const [modalShow, setModalShow] = React.useState(false);

  const cart = useSelector((state) => state.cart);
  const storeConfig = useSelector((state) => state.storeConfig);
  const { store } = storeConfig;

  const minimumOrderValue = store.minimumOrderValue || 100;
  const deliveryCharges = store.deliveryCharges || 10;

  if (!cart.shippingAddress.address) {
    history.push("/shipping");
  } else if (!cart.paymentMethod) {
    history.push("/payment");
  } else if (cart?.cartItems?.length === 0) {
    history.push("/");
  }
  //   Calculate prices
  const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  };

  cart.itemsPrice = addDecimals(
    cart.cartItems.reduce((acc, item) => acc + item.price * item.qty, 0)
  );
  cart.shippingPrice = addDecimals(
    cart.itemsPrice >= minimumOrderValue ? 0 : deliveryCharges
  );
  cart.taxPrice = addDecimals(Number((0.0 * cart.itemsPrice).toFixed(2)));
  cart.totalPrice = (
    Number(cart.itemsPrice) + Number(cart.shippingPrice)
  ).toFixed(2);

  const orderCreate = useSelector((state) => state.orderCreate);
  const { order, success, error } = orderCreate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userInfo.token}`,
    },
  };

  console.log(cart);

  useEffect(() => {
    if (success) {
      history.push(`/order/${order._id}`);
      console.log(order);
      dispatch({ type: USER_DETAILS_RESET });
      dispatch({ type: ORDER_CREATE_RESET });
      paymentHandler();
    }
    // eslint-disable-next-line
  }, [history, success]);

  const placeOrderHandler = async () => {
    if (cart.paymentMethod === "Cash") {
      setModalShow(true);
      setTimeout(() => {
        setModalShow(false);
        dispatch(
          createOrder(
            {
              orderItems: cart.cartItems,
              shippingAddress: cart.shippingAddress,
              paymentMethod: cart.paymentMethod,
              itemsPrice: cart.itemsPrice,
              shippingPrice: cart.shippingPrice,
              taxPrice: cart.taxPrice,
              totalPrice: cart.totalPrice,
              phone: cart.shippingAddress.phone,
            },
            store._id
          )
        );
      }, 2000);
    } else {
      const result = await axios.post(
        `/api/payment/marketplace/${marketplaceId}/create-order`,
        {
          amount: cart.totalPrice * 100,
        },
        config
      );
      const { id: order_id } = result.data;
      const {
        data: { key: razorpayKey },
      } = await axios.get(
        `/api/payment/marketplace/${marketplaceId}/get-razorpay-key`,
        config
      );

      setRazorPayOrderId(order_id);
      setRazorPayKey(razorpayKey);

      dispatch(
        createOrder({
          orderItems: cart.cartItems,
          shippingAddress: cart.shippingAddress,
          paymentMethod: cart.paymentMethod,
          itemsPrice: cart.itemsPrice,
          shippingPrice: cart.shippingPrice,
          taxPrice: cart.taxPrice,
          totalPrice: cart.totalPrice,
          phone: cart.shippingAddress.phone,
          razorPay: {
            orderId: order_id,
            paymentId: null,
            signature: null,
          },
        })
      );
    }
  };

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body
          style={{
            backgroundColor: "white",
            color: "#000",
            borderRadius: "0.2rem",
          }}
        >
          {" "}
          <Modal.Title
            style={{
              backgroundColor: "white",
              color: "green",
              textAlign: "center",
            }}
            id="contained-modal-title-vcenter"
          >
            Order Placed SuccessFully
          </Modal.Title>
          <div
            style={{
              backgroundColor: "white",
              color: "green",
              textAlign: "center",
            }}
          >
            <MdOutlineCheckCircle size={80} />
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  const paymentHandler = () => {
    if (window.Android) {
      const options = {
        amount: cart.totalPrice * 100,
        token: userInfo.token,
        order_id: razorPayOrderId,
      };
      window.Android.makePayment(JSON.stringify(options));
      history.push(`/myorders`);
    } else if (cart.paymentMethod === "Cash") {
      console.log("palcing order using COD");
    } else {
      addRazorPayScript();
    }
  };

  const addRazorPayScript = async () => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.onerror = () => {
      alert("Razorpay SDK failed to load. Are you online?");
    };
    script.onload = async () => {
      try {
        setRazorPayLoading(true);
        const options = {
          key: razorPayKey,
          amount: cart.totalPrice * 100,
          currency: "INR",
          name: "Order Details",
          description: "Total Amount to be paid",
          order_id: razorPayOrderId,
          handler: async function (response) {
            const result = await axios.post(
              `/api/payment/pay-order?razorPayOrderId=${razorPayOrderId}`,
              {
                amount: cart.totalPrice,
                razorpayPaymentId: response.razorpay_payment_id,
                razorpayOrderId: response.razorpay_order_id,
                razorpaySignature: response.razorpay_signature,
              },
              config
            );

            console.log(result);
          },
          prefill: {
            name: userInfo.name,
            email: userInfo.email,
            contact: userInfo.phone,
          },
          notes: {
            address: cart.shippingAddress,
          },
          theme: {
            color: "#2aa198",
          },
        };

        setRazorPayLoading(false);
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      } catch (err) {
        alert(err);
        setRazorPayLoading(false);
      }
    };
    document.body.appendChild(script);
  };

  return (
    <>
      <Col xs={12} md={12}>
        <CheckoutSteps step1 step2 step3 step4 />
      </Col>
      <Row>
        <Col xs={12} md={8}>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <h2>Shipping</h2>
              <p>
                <strong>Address: </strong>
                {cart.shippingAddress.address
                  .split(":")
                  .slice(0, 1)
                  .join("")}, {cart.shippingAddress.pinCode}
              </p>
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>Payment Method</h2>
              <strong>Method: </strong>
              {cart.paymentMethod}
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>Order Items</h2>
              {cart.cartItems?.length === 0 ? (
                <Message>Your cart is empty</Message>
              ) : (
                <ListGroup variant="flush">
                  {cart.cartItems.map((item, index) => (
                    <ListGroup.Item key={index}>
                      <Row>
                        <Col xs={3} md={1}>
                          <Image
                            src={item.image}
                            alt={item.name}
                            fluid
                            rounded
                          />
                        </Col>
                        <Col xs={4} md={4}>
                          <Link to={`/product/${item.product}`}>
                            {item.name}
                          </Link>
                        </Col>
                        <Col className="textSizeM" xs={5} md={4}>
                          {item.qty} x ₹{item.price} = ₹{item.qty * item.price}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col className="mb-5" xs={12} md={4}>
          <Card>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <h2>Order Summary</h2>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Items</Col>
                  <Col>₹{cart.itemsPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Shipping</Col>
                  <Col>₹{cart.shippingPrice}</Col>
                </Row>
              </ListGroup.Item>
              {minimumOrderValue - cart.itemsPrice > 0 && (
                <ListGroup.Item>
                  <Row>
                    <Col style={{ color: "green" }}>
                      Add items worth{" "}
                      <span style={{ color: "#000" }}>
                        ₹{minimumOrderValue - cart.itemsPrice}
                      </span>{" "}
                      more for Free Delievry
                    </Col>
                  </Row>
                </ListGroup.Item>
              )}
              <ListGroup.Item>
                <Row>
                  <Col>Total</Col>
                  <Col>₹{cart.totalPrice}</Col>
                </Row>
                {error && <Message variant="danger">{error}</Message>}
              </ListGroup.Item>

              <ListGroup.Item className="d-grid gap-2">
                <Button
                  type="button"
                  id="place-order"
                  disabled={razorPayLoading || cart.cartItems === 0}
                  className="btn-block btn-secondary"
                  size="lg"
                  onClick={placeOrderHandler}
                >
                  Place Order
                </Button>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
      </Row>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default PlaceOrderScreen;
