import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import { Button, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";

const OnBoardStoreListScreen = () => {
  const [storeList, setStoreList] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const orderList = useSelector((state) => state.orderList);
  const { loading, error /* , orders, pages, page  */ } = orderList;

  useEffect(() => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    axios
      .get(`/api/config`, config)
      .then((res) => {
        console.log(res.data);
        setStoreList(res.data.stores);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [userInfo.token]);

  const openStore = (storeId) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    axios
      .get(`/api/store/${storeId}/products/publish`, config)
      .then((res) => {
        console.log(res.data);
        setStoreList(res.data.stores);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const closeStore = (storeId) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    axios
      .get(`/api/store/${storeId}/products/unpublish`, config)
      .then((res) => {
        console.log(res.data);
        setStoreList(res.data.stores);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="warning">{error}</Message>
      ) : (
        <>
          <Button variant="secondary">
            <Link to="/admin/invoice/create" style={{ color: "white" }}>
              create invoice
            </Link>
          </Button>
          <Table
            striped
            bordered
            hover
            size="sm"
            style={{ textAlign: "center" }}
            className="mt-3"
          >
            <thead>
              <tr>
                <th>#</th>
                <th>Store Name</th>
                <th>Company</th>
                <th>GSTIN</th>
                <th>Address</th>
                <th>Pin Code</th>
                <th>Edit</th>
                <th>Open</th>
                <th>Close</th>
              </tr>
            </thead>
            {storeList && (
              <tbody>
                {storeList.map((store, index) => (
                  <tr>
                    <td>{index}</td>
                    <td>{store.name}</td>
                    <td>{store.company}</td>
                    <td>{store.gstNumber}</td>
                    <td>{store.address}</td>
                    <td>{store.pinCode}</td>
                    <td>
                      <LinkContainer to={`/superadmin/store/${store._id}/edit`}>
                        <Button variant="light" className="btn-sm">
                          <i
                            style={{ color: "blue" }}
                            className="fas fa-edit"
                          ></i>
                        </Button>
                      </LinkContainer>
                    </td>
                    <td>
                      <Button
                        onClick={() => {
                          openStore(store._id);
                        }}
                        variant="light"
                        className="btn-sm"
                      >
                        <i
                          style={{ color: "blue" }}
                          className="fas fa-edit"
                        ></i>
                      </Button>
                    </td>
                    <td>
                      <Button
                        onClick={() => {
                          closeStore(store._id);
                        }}
                        variant="light"
                        className="btn-sm"
                      >
                        <i
                          style={{ color: "blue" }}
                          className="fas fa-edit"
                        ></i>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </Table>
        </>
      )}
    </div>
  );
};

export default OnBoardStoreListScreen;
