import React, { useEffect, useState } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Button, Table, Form } from "react-bootstrap";
import Resizer from "react-image-file-resizer";
import { useSelector } from "react-redux";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import axios from "axios";

const fetchMarketplaceData = async (id, token) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(`/api/marketplace/${id}`, config);
  return response.data;
};

const MarketPlacesEdit = ({ match }) => {
  const marketID = match.params.id;

  const [marketCategories, setMarketCategories] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [subCategoryName, setSubCategoryName] = useState("");
  const [images, setImages] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [updateMessage, setUpdateMessage] = useState(null);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, loading, error } = userLogin;

  useEffect(() => {
    if (userInfo) {
      const fetchData = async () => {
        try {
          const data = await fetchMarketplaceData(marketID, userInfo.token);
          setMarketCategories(data.categories);
        } catch (err) {
          setApiError(err.message);
          console.log(err);
        }
      };
      fetchData();
    }
  }, [marketID, userInfo]);

  const newCategory = {
    id: "68",
    image: images.toString(),
    name: categoryName,
    subCategories: [
      {
        id: "45",
        image: "gyf3g",
        name: subCategoryName,
      },
    ],
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const updateData = { categories: [...marketCategories, newCategory] };
      const response = await axios.patch(
        `/api/marketplace/${marketID}`,
        updateData,
        config
      );
      setUpdateMessage("Category Updated Succesfully");
      console.log("successfully data sent", response);
    } catch (err) {
      setApiError(err.message);
      console.log(err);
    }
  };

  // Function to handle category deletion
  const deleteCategoryHandler = async (categoryId) => {
    try {
      // Filter out the category to be deleted
      const updatedCategories = marketCategories.filter(
        (category) => category.id !== categoryId
      );

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const updateData = { categories: updatedCategories };
      await axios.patch(`/api/marketplace/${marketID}`, updateData, config);

      // Update local state with the filtered categories
      setMarketCategories(updatedCategories);
    } catch (err) {
      setApiError(err.message);
      console.log(err);
    }
  };

  const uploadFileHandler = async (e) => {
    setUploading(true);
    const formData = new FormData();
    const files = e.target.files;
    if (files) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        Resizer.imageFileResizer(
          file,
          750,
          750,
          "JPEG",
          400,
          0,
          (blob) => {
            formData.append("images", blob, categoryName + file.name);
            axios
              .post("/api/upload", formData)
              .then((response) => {
                setUploading(false);
                const updatedImages = response.data.split(",");

                console.log(updatedImages);
                setImages(updatedImages);
              })
              .catch((err) => {
                console.log(err);
                setUploading(false);
              });
          },
          "blob"
        );
      }
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="warning">{error}</Message>
      ) : apiError ? (
        <Message variant="danger">{apiError}</Message>
      ) : (
        <>
          {updateMessage && (
            <Message variant="success">{updateMessage}</Message>
          )}{" "}
          {/* Display the update message if set */}
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="name">
              <label className="mt-2">Category Name </label>
              <Form.Control
                type="name"
                placeholder="Enter Product Name"
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="images">
              <label className="mt-2">Images</label>
              <Form.Control
                type="text"
                placeholder="Enter Image URL "
                value={images.join(", ")}
                onChange={(e) => setImages(e.target.value)}
              ></Form.Control>
              <label className="btn btn-secondary">
                Choose File
                <Form.File
                  id="image-file"
                  multiple
                  hidden
                  custom
                  onChange={uploadFileHandler}
                ></Form.File>
              </label>
              {uploading && <Loader />}
            </Form.Group>
            <Form.Group controlId="name">
              <label className="mt-2">subCategory Name </label>
              <Form.Control
                type="name"
                placeholder="Enter Product Name"
                value={subCategoryName}
                onChange={(e) => setSubCategoryName(e.target.value)}
              ></Form.Control>
            </Form.Group>
            {/* <Form.Group controlId="name">
								<label className="mt-2">Name </label>
								<Form.Control
									type="name"
									placeholder="Enter Product Name"
									value={name}
									onChange={(e) => setName(e.target.value)}
								></Form.Control>
							</Form.Group> */}

            <Form.Group className="d-grid gap-2 mt-2 mb-5">
              <Button variant="secondary" size="lg" type="submit">
                Update
              </Button>
            </Form.Group>
          </Form>
          <Table
            striped
            bordered
            hover
            size="sm"
            style={{ textAlign: "center" }}
            className="mt-3"
          >
            <thead>
              <tr>
                <th>#</th>
                <th>ID</th>
                <th>image</th>
                <th>categories</th>
                <th>subcategory</th>
                <th>Edit</th>
                <th>Delete</th> {/* Add Delete header */}
              </tr>
            </thead>
            <tbody>
              {marketCategories?.map((category, index) => (
                <tr key={category._id}>
                  <td key={category._id}>{index}</td>

                  <td>{category.id}</td>
                  <td>
                    <img
                      style={{ height: "40px", width: "40px" }}
                      src={category.image}
                      alt="img"
                    />
                  </td>
                  <td>{category.name}</td>
                  <td>
                    {category.subCategories?.map((subcategory, subIndex) => (
                      <span key={subIndex}>{subcategory.name},</span>
                    ))}
                  </td>
                  <td>
                    <LinkContainer
                      to={`/superadmin/marketplace/${marketID}/category/${category.name}`}
                    >
                      <Button variant="light" className="btn-sm">
                        Edit
                      </Button>
                    </LinkContainer>
                  </td>
                  <td>
                    {" "}
                    {/* Add delete button */}
                    <Button
                      variant="danger"
                      className="btn-sm"
                      onClick={() => deleteCategoryHandler(category.id)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </>
  );
};

export default MarketPlacesEdit;
