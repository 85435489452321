import React, { useEffect, useState } from "react";
import { Col, Row } from 'react-bootstrap'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { useDispatch, useSelector } from 'react-redux'
import Alert from 'react-bootstrap/Alert'
import axios from "axios";

export const ExpiringProducts = ({ history }) => {
  const dispatch = useDispatch()

  const [expiringProducts, setExpiringProducts] = useState([])
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false)



  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (userInfo && userInfo.role.toLowerCase().includes("admin")) {
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      axios
        .get('/api/store/products/expiringstock', config)
        .then((res) => {
          setExpiringProducts(res.data)
          setLoading(false)
        })
        .catch((err) => {
          setError(true)
          console.log(err)
        })

    } else {
      history.push('/login')
    }
  }, [dispatch, history, userInfo])

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='warning'>{error}</Message>
      ) : (
        <>
          <p className='DashboardHeading'>Expiry Alert</p>
          <Row>
            <Col className='text-center' style={{ color: 'black' }} md={8}>
              <h5>Product Name </h5>
            </Col>
            <Col className='text-center' style={{ color: 'black' }}>
              <h5> Date</h5>
            </Col>
          </Row>
          {expiringProducts.map((product) => (
            <Alert variant={product.expiryDate < new Date() ? 'warning' : 'light '} style={{ padding: '0' }}>
              <Row key={product.name}>
                <Col md={8}>
                  <h5 className='text-center' style={{ color: 'black' }}>
                    {product.name}
                  </h5>
                </Col>
                <Col>
                  <h5 className='text-center' style={{ color: 'black' }}>
                    {product.expiryDate.split('T')[0]}
                  </h5>
                </Col>
              </Row>
            </Alert>
          ))}
        </>
      )}
    </>
  )
}
