import React from 'react'
/* import './aboutUs.css' */

const AboutUs = () => {
  return (
    <div>
      {/* <!-- ---------------------navbar ------------------------> */}
      {/* <nav className='navbar navbar-expand-md ItemColor'>
        <div className='container-fluid navContent nav1'>
          <a className='navbar-brand' href='/'>
            {' '}
            <strong> Retail Center</strong>
          </a>

          <button className='navbar-toggler' type='button' data-bs-toggle='collapse' data-bs-target='#navbarNav' aria-controls='navbarNav' aria-expanded='false' aria-label='Toggle navigation'>
            <span className='navbar-toggler-icon'></span>
          </button>

          <div className='collapse navbar-collapse' id='navbarNav'>
            <ul className='navbar-nav headLink'>
              <li className='nav-item'>
                <a className='nav-link' href='#home'>
                  Home
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link' href='#service'>
                  Service
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link' href='#support'>
                  Support
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link' href='#contact'>
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav> */}
      {/*   <!-- -----------------About US ------------ --> */}
      <section className='container ' id='home'>
        <a className='navbar-brand' href='#contact'>
          <h4 className='mb-3 mt-2'>
            {' '}
            <strong> Contact Us </strong>{' '}
          </h4>
        </a>
        {/* <div className='textcenter'>
          <span className='shtext1'>Retail Center</span>
          <span className='seperator1'></span>
          {/* <!-- <h1>Drop Us a Mail</h1>--> 
        </div> */}
        <div className='row'>
          <div className='col-sm-6 mb-2'>
            <div className='ratio ratio-4x3'>
              <iframe title='About Over Company' width='500' height='500' src='https://www.youtube.com/embed/0lbzmZeS-BY'>
                {' '}
              </iframe>
            </div>
          </div>
          <div className='col-sm-1 mb-2'></div>

          <div className='col-sm-5'>
            <p className='card-text'>
              <strong className='card-title'> Mission : </strong> Our mission is to digitilize retaile outlets , small or big , to be able increase their operational effeciency thereby enhancing Sales
              and Customer Service.
            </p>
            <br />
            <p>
              <strong>Vision : </strong> Our Visison is to make digitilisation affordable and accesable to one and all by building cost effective ,high quality digital solutions.
            </p>
            <br />
            <p className='card-text'>
              <strong>Product : </strong>
              <b style={{ color: ' #2aa198' }}> Retail Assistant</b> is a digital solution that helps you to manage your retail outlet. It helps you to manage your inventory, sales, and customer
              service.
            </p>
            <br />
            <p className='card-text'>
              <strong>Team : </strong> We are a team of digital developers and designers who are passionate about digital solutions. We are here to help you to build a better digital presence for your
              business.
            </p>
            <br />
            <p className='card-text'>
              <strong>Technology :</strong>Secure, Scalable, User Friendly and reliable technology is the key to our success. We use the <b>MERN</b> stack to Build and Host on <b>AWS </b>and
              <b> GCP</b> make sure that your business is secure and reliable.
            </p>
          </div>
        </div>
      </section>
      {/* <!--------------------service section----------------------> */}
      <section className='container mt-5 mb-5' id='service'>
        <div className='textcenter'>
          <span className='shtext1'>Products & Plans</span>
          <span className='seperator1'></span>
          {/*  <!-- <h1>Drop Us a Mail</h1>--> */}
        </div>
        <div className='row spaceEvenly'>
          {/*  <!-- <h2 className="shtext1">Our Service</h2> --> */}
          <div className='col-lg-4 col-md-6 col-sm-10 mb-3'>
            <div className='card'>
              <div className='card-body'>
                <h2 className='card-title'>
                  <strong>Basic</strong>
                </h2>
                <ul className='card-text'>
                  <li>
                    Billing <i className='fas fa-check fasCheck'></i>
                  </li>
                  <li>
                    Inventory <i className='fas fa-check fasCheck'></i>
                  </li>
                  <li>
                    Order Management <i className='fas fa-check fasCheck'></i>
                  </li>
                  <li>Koisk</li>
                  <li>Multi desk (counter)</li>
                  <li>Accounting</li>
                  <li>Analytics</li>
                  <li>Online Marketing</li>
                  <li>E-mail Support or Call</li>
                  <li>24x7 Support</li>
                </ul>
                <div className='d-grid gap-1 col-4 mx-auto'>
                  <button type='button' className='btn buttonStyle'>
                    <a href='#contact'>Contact Us</a>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-4 col-md-6 col-sm-10 mb-3'>
            <div className='card'>
              <div className='card-body'>
                <h2 className='card-title'>
                  <strong>Pro</strong>
                </h2>

                <ul className='card-text'>
                  <li>
                    Billing <i className='fas fa-check fasCheck'></i>
                  </li>
                  <li>
                    Inventory <i className='fas fa-check fasCheck'></i>
                  </li>
                  <li>
                    Order Management <i className='fas fa-check fasCheck'></i>
                  </li>
                  <li>
                    Koisk <i className='fas fa-check fasCheck'></i>
                  </li>
                  <li>
                    Multi desk (counter) <i className='fas fa-check fasCheck'></i>
                  </li>
                  <li>
                    Accounting <i className='fas fa-check fasCheck'></i>
                  </li>
                  <li>
                    Analytics <i className='fas fa-check fasCheck'></i>
                  </li>
                  <li>Online Marketing</li>
                  <li>E-mail Support or Call</li>
                  <li>24x7 Support</li>
                </ul>
                <div className='d-grid gap-1 col-4 mx-auto'>
                  <button type='button' className='btn buttonStyle'>
                    <a href='#contact'>Contact Us</a>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-4 col-md-6 col-sm-10 mb-3'>
            <div className='card'>
              <div className='card-body'>
                <h2 className='card-title'>
                  <strong> Enterprise</strong>
                </h2>
                <ul className='card-text'>
                  <li>
                    Billing <i className='fas fa-check fasCheck'></i>
                  </li>
                  <li>
                    Inventory <i className='fas fa-check fasCheck'></i>
                  </li>
                  <li>
                    Order Management <i className='fas fa-check fasCheck'></i>
                  </li>
                  <li>
                    Koisk <i className='fas fa-check fasCheck'></i>
                  </li>
                  <li>
                    Multi desk (counter) <i className='fas fa-check fasCheck'></i>
                  </li>
                  <li>
                    Accounting <i className='fas fa-check fasCheck'></i>
                  </li>
                  <li>
                    Analytics <i className='fas fa-check fasCheck'></i>
                  </li>
                  <li>
                    Online Marketing <i className='fas fa-check fasCheck'></i>
                  </li>
                  <li>
                    E-mail Support or Call <i className='fas fa-check fasCheck'></i>
                  </li>
                  <li>
                    24x7 Support <i className='fas fa-check fasCheck'></i>
                  </li>
                </ul>
                <div className='d-grid gap-1 col-4 mx-auto'>
                  <button type='button' className='btn buttonStyle'>
                    <a href='#contact'>Contact Us</a>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*  <!--------------------------our engineers------------------------> */}
      {/* <section id='support' style={{ display: 'none' }}>
        <div className='container'>
          <div className='textcenter'>
            <span className='seperator1 mb-5 mt-5'></span>
            {/* <!-- <h1>Drop Us a Mail</h1>--> 
          </div>
          <!-- -----------big card 2------------- --> 
          <div className='row mb-3 mt-5'>
            <div className='col-lg-6 col-md-6 col-sm-12 mb-3'>
              <div className='card'>
                <div className='row g-0'>
                  <div className='col-md-4'>
                    <img src='./images/mufeez2.jpeg' style={{ height: '200px' }} className='img-fluid mx-auto' alt='...' />
                  </div>
                  <div className='col-lg-6 col-md-6'>
                    <div className='card-body'>
                      <h3 className='card-title'>Mufeez Ahmed</h3>
                      <p className='card-text'>
                        <b>Founder / Maneging Director</b> <br />
                        11+ Years of Experience In Software Development Industry
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-md-6 col-md-6 col-sm-12'>
              <div className='card'>
                <div className='row g-0'>
                  <div className='col-md-4'>
                    <img src='./images/habeeb.jpeg' style={{ height: '200px' }} className='img-fluid mx-auto' alt='...' />
                  </div>
                  <div className='col-md-8'>
                    <div className='card-body'>
                      <h3 className='card-title'>Habeeb Ahmed</h3>
                      <p className='card-text'>
                        <b>Management / TMC Councillor </b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='textcenter'>
            <span className='shtext1'>Team Member's </span>
            {/*  <!-- <h1>Drop Us a Mail</h1>--> 
          </div>
          {/* <!-- ----------------1 small card 3----------- --> 
          <div className='row mb-3'>
            <div className='col-lg-3 col-md-4 col-sm-6 mb-3'>
              <div className='card text-center' style={{ height: '300px' }}>
                <img src='./images/haseeb1.jpeg' className='img-fluid card-img-top ImgCard mx-auto' alt='img' />
                <div className='card-body'>
                  <h5 className='card-title'>Mohammed Haseeb</h5>
                  <h6 className='card-text'>Software Developer ( ISTQB certified)</h6>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-4 col-sm-6 mb-3'>
              <div className='card text-center' style={{ height: '300px' }}>
                <img src='./images/dawud.jpeg' className='card-img-top ImgCard mx-auto img-fluid' alt='img' />
                <div className='card-body'>
                  <h5 className='card-title'>Mohammed Dawud</h5>
                  <h6 className='card-text'>Data Engineer</h6>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-4 col-sm-6 mb-3'>
              <div className='card text-center' style={{ height: '300px' }}>
                <img src='./images/zain.jpeg' className='card-img-top ImgCard mx-auto img-fluid' alt='img' />
                <div className='card-body'>
                  <h5 className='card-title'>Md Zain-ul Abedin</h5>
                  <h6 className='card-text'>Software Analyst</h6>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-4 col-sm-6 mb-3'>
              <div className='card text-center' style={{ height: '300px' }}>
                <img src='./images/muzammil.jpeg' className='card-img-top ImgCard mx-auto img-fluid' alt='img' />
                <div className='card-body'>
                  <h5 className='card-title'>Mohammed Muzammil</h5>
                  <h6 className='card-text'>Quality Engineer</h6>
                </div>
              </div>
            </div>

            <div className='col-lg-3 col-md-4 col-sm-6 mb-3'>
              <div className='card text-center' style={{ height: '300px' }}>
                <img src='./images/raghavendra.jpg' className='card-img-top ImgCard mx-auto img-fluid' alt='img' />
                <div className='card-body'>
                  <h5 className='card-title'>Raghavendra</h5>
                  <h6 className='card-text'>Quality Engineer</h6>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-4 col-sm-6 mb-3'>
              <div className='card text-center' style={{ height: '300px' }}>
                <img src='./images/engineers.jpg' className='card-img-top ImgCard mx-auto img-fluid' alt='img' />
                <div className='card-body'>
                  <h5 className='card-title'>Rehan Siddiqui</h5>
                  <h6 className='card-text'>Software Developer</h6>
                  <h6 className='card-text'></h6>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-4 col-sm-6 mb-3'>
              <div className='card text-center' style={{ height: '300px' }}>
                <img src='./images/asif.jpeg' className='card-img-top ImgCard mx-auto img-fluid' alt='img' />
                <div className='card-body'>
                  <h5 className='card-title'>Md Asif Hussain</h5>
                  <h6 className='card-text'>Software Developer</h6>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-4 col-sm-6 mb-3'>
              <div className='card text-center' style={{ height: '300px' }}>
                <img src='./images/engineers.jpg' className='card-img-top ImgCard mx-auto img-fluid' alt='img' />
                <div className='card-body'>
                  <h5 className='card-title'>Md Sayeed</h5>
                  <h6 className='card-text'>System Engineer</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <!--FORM SECTION--> */}
      <div className='container' id='contact'>
        <div className='innerwrap'>
          <section className='section1 clearfix'>
            <div className='textcenter'>
              <span className='shtext1'>Contact Us</span>
              <span className='seperator'></span>
            </div>
          </section>

          <section className='section2 clearfix'>
            {/* <div className='col2 column2 first'>
              <div className='sec2contactform'>
                <h3 className='sec2frmtitle'>Want to Know More? Drop Us a Mail</h3>

                <form name='mufeedForm' action='mailto:mufeedproductsandservices@gmail.com' method='post'>
                  <div className='clearfix'>
                    <input className='col2 first' type='text' placeholder='FirstName' />
                    <input className='col2 last' type='text' placeholder='LastName' />
                  </div>
                  <div className='clearfix'>
                    <input className='col2 first' type='text' placeholder='Email Address' />
                    <input className='col2 last' type='text' placeholder='Contact Number' />
                  </div>
                  <div className='clearfix'>
                    <textarea placeholder='Your message here...' cols='30' rows='7'></textarea>
                  </div>
                  <div>
                    <input type='submit' value='Submit' />
                  </div>
                </form>
              </div>
            </div> */}

            <div className='col2 column1 last'>
              <div className='mapouter'>
                <div className='gmap_canvas'>
                  <iframe
                    title='company location'
                    className='gmap_iframe'
                    width='100%'
                    frameborder='0'
                    scrolling='no'
                    marginheight='0'
                    marginwidth='0'
                    src='https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=mufeed product and services chitguppa&amp;t=k&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed'
                  ></iframe>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      {/*  <!-- Footer --> */}
      <section className='container' id='about'>
        <footer className='text-center text-lg-start bg-light text-muted'>
          <div className='container text-center text-md-start mt-5'>
            {/* <!-- Right --> */}
            <section className='mb-4 '>
              <div className='card-wrapper  '>
                {/* <!-- Twitter --> */}
                <a className='btn btn-primary btn-floating m-1' rel='' style={{ backgroundColor: '#55acee' }} href='https://twitter.com/mufeez1' role='button'>
                  <i className='fab fa-twitter'></i>
                </a>
                {/*               <!-- Linkedin -->
                 */}{' '}
                <a className='btn btn-primary btn-floating m-1' style={{ backgroundColor: '#0082ca' }} href='https://www.linkedin.com/in/mufeezahmed/' role='button'>
                  <i className='fab fa-linkedin-in'></i>
                </a>
              </div>
            </section>
            <div className='row mt-3 mb-4'>
              {/*             <!-- Grid column -->
               */}{' '}
              <div className='col-md-3 col-lg-4 col-xl-3 mx-auto mb-4'>
                {/*               <!-- Content -->
                 */}{' '}
                <h6 className='text-uppercase fw-bold mb-4'>
                  <i className='fas fa-gem me-3'></i>Company Details
                </h6>
                <p>We are a registered Startup under Startup India Program with Focus on Innvoative Solutions to help not just Enterprises but Local bussinesses as well</p>
              </div>
              {/*             <!-- Grid column -->
               */}
              {/*             <!-- Grid column -->
               */}{' '}
              <div className='col-md-2 col-lg-2 col-xl-2 mx-auto mb-4'>
                {/*               <!-- Links -->
                 */}{' '}
                <h6 className='text-uppercase fw-bold mb-4'>Products</h6>
                <p>
                  <a href='#service' className='text-reset'>
                    Basic
                  </a>
                </p>
                <p>
                  <a href='#service' className='text-reset'>
                    Pro
                  </a>
                </p>
                <p>
                  <a href='#service' className='text-reset'>
                    Enterprise
                  </a>
                </p>
              </div>
              {/*             <!-- Grid column -->
               */}
              {/*             <!-- Grid column -->
               */}{' '}
              <div className='col-md-7 col-lg-6 col-xl-4 mx-auto mb-md-0 mb-4'>
                {/*               <!-- Links -->
                 */}{' '}
                <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
                <p>
                  <a
                    className='fas fa-home me-3'
                    href='https://www.google.com/maps/place/MUFEED+PRODUCTS+AND+SERVICES/@17.698906,77.216225,6710m/data=!3m1!1e3!4m5!3m4!1s0x0:0xf7ce44c862937587!8m2!3d17.6989027!4d77.2162992?hl=en'
                  >
                    {' '}
                    Chitguppa, 585412, India
                  </a>
                </p>
                <p>
                  <a href='mailto:mufeez@retailcenter.io' className='fas fa-envelope me-3'>
                    {' '}
                    mufeez@retailcenter.io
                  </a>
                </p>
                <p>
                  <a href='tel:+918861710458' className='fas fa-phone me-3'>
                    {' '}
                    +918861710458{' '}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </footer>
        {/*       <!-- Footer -->
         */}{' '}
      </section>
      {/*     <!-- -----js bootstap----- -->
       */}{' '}
      <script
        src='https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js'
        integrity='sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM'
        crossorigin='anonymous'
      ></script>
    </div>
  )
}

export default AboutUs
